Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpPostMethod = "PUT";
exports.postTeacherDetails = "account_block/accounts/teacher_details";
exports.httpPatchMethode = "PATCH";
exports.activateUser = "account_block/accounts/";
exports.stateDataContentType = 'application/json';
exports.stateDataMethod='GET';
exports.stateEndPoint='account_block/schools/states';
exports.districtDataContentType = 'application/json';
exports.districtDataMethod='GET';
exports.districtEndPoint='account_block/schools/districts';
exports.schoolDataContentType = 'application/json';
exports.schoolDataMethod='GET';
exports.schoolEndPoint='account_block/schools';
exports.teacherSelfDataContentType = 'application/json';
exports.teacherSelfDataMethod='POST';
exports.teacherSelfEndPoint='account_block/self_onboardings';
// Customizable Area End
