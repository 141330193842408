export const titleIcon = require("../assets/Orders.png");
export const pendingOrdersIcon = require("../assets/PendingOrders.png");
export const deliveredPaidButton = require("../assets/DeliverdPaidButton.png")
export const deliveredWarpPayButton = require("../assets/DeliveredWarpPayButton.png")
export const shippedNotPaidButton = require("../assets/ShippedNotPaidButton.png")
export const notShippedNotPaidButton = require("../assets/NotShippedNotPaidButton.png")
export const deliveredAtWarpSpeedButton = require("../assets/DeliveredAtWarpSpeedButton.png")
export const filterIcon = require("../assets/Filter.png")
export const funnelIcon = require("../assets/Funnelicon.png")
export const PackingListIcon = require("../assets/PackingList.png")
export const ShipmentDetailsIcon = require("../assets/ShipmentDetails.png")
export const CloseIcon = require("../assets/close.svg")

