import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Grid,
  FormHelperText
} from '@material-ui/core';
import "../../assets/addcredits.scss"
import { cricle,close,arrowImg} from '../assets';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormValues } from '../AddCreditsController';
import LoaderComponent from '../../../../components/src/NativeWebRouteWrapper/LoaderComponent';
import { getCommaSeperator, getUserDetails } from '../../../../components/src/NativeWebRouteWrapper/Utils';
import { validateDomainPartLength, validateLocalPartLength } from '../../../../components/src/utility';

const validationSchema = Yup.object({
  procurementContactName: Yup.string()
    .min(2, 'Please enter a valid name.')
    .max(50, 'Please enter a valid name.')
    .matches(/^[a-zA-Z\s'-]+$/, 'Please enter a valid name.')
    .required('This field is required.'),
    procurementContactEmail: Yup.string()
    .required('This field is required.')
    .matches(
      /^[A-Za-z0-9](?!.*\.\.)([A-Za-z0-9._%+-])*[A-Za-z0-9]@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      'Please enter a valid email address.'
    )
    .test(
      'local-part-length',
      'Please enter a valid email address.',
       validateLocalPartLength
    )
    .test(
      'domain-length',
      'Please enter a valid email address.',
       validateDomainPartLength
    ),
  
});


const CreditDialog: React.FC<{ open: boolean; handleClose: () => void ,credit:number, price:number,formValues:FormValues,loading:boolean;handleQuoteData: (values: FormValues, credit: number) => void;}> = ({ open, handleClose,credit,price,formValues,handleQuoteData,loading }) => {
  const userData = getUserDetails();
  const userEmail = userData.email;
  const userName =userData.first_name+" "+userData.last_name;
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="credit-quote-dialog">
      <DialogTitle disableTypography className="dialog-title">
       <div  className='closeImage' onClick={handleClose}>
        <img src={close}/>
        </div>  
        <Grid className="box" container spacing={2}>  
        <Grid item>
        <img src={cricle}/>
        </Grid> 
        <Grid item>
        <Typography variant="h5" className="dialog-title-text">{credit} Credits</Typography>
        <Typography variant="h6" className="credit-info">${getCommaSeperator(price)}</Typography>
        </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <Typography variant="h5" className='titlecredit'>
          {credit} Credit Quote Request
        </Typography>
        <Typography className='creditdis'>
        After clicking submit, your Betabox Account Manager will email a quote to the procurement contact that you identify below for purchasing a {credit} Credit Package.
        </Typography>
        <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
       
      handleQuoteData(values,credit)
      }}
    >
      {(props) => (
        <Form>
            <Grid container spacing={2} className="dialog-container">
              <Grid item sm={12} md={6}>
                <label htmlFor="procurement-contact-name" style={{ marginBottom: '8px', display: 'block',color:"#101828"}}>
                  Procurement Contact Name
                </label>
                <Field
                  name="procurementContactName"
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  className="input-field"
                  id="procurement-contact-name"
                  inputProps={{ maxLength: 50 }}
                   error={
                    props.errors.procurementContactName && props.touched.procurementContactName
                  }
                />
                  {props.errors.procurementContactName && props.touched.procurementContactName && (
                    <FormHelperText error={true}>
                      {props.errors.procurementContactName}
                    </FormHelperText>
                  )}
              </Grid>

              <Grid item sm={12} md={6}>
                <label htmlFor="procurement-contact-email" style={{ marginBottom: '8px', display: 'block',color:"#101828" }}>
                  Procurement Contact Email
                </label>
                <Field
                  name="procurementContactEmail"
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  className="input-field email-field"
                  id="procurement-contact-email"
                  data-test-id="email"
                  error={
                    props.errors.procurementContactEmail && props.touched.procurementContactEmail
                  }
                />
                {props.errors.procurementContactEmail && props.touched.procurementContactEmail && (
                    <FormHelperText error={true}>
                      {props.errors.procurementContactEmail}
                    </FormHelperText>
                  )}
              </Grid>

              <Grid item sm={12} md={6}>
                <label htmlFor="requester-contact" style={{ marginBottom: '8px', display: 'block' ,color:"#101828"}}>
                  Requester Contact
                </label>
                <Field
                  name="requesterContact"
                  as={TextField}
                  variant="outlined"
                  fullWidth     
                  className="nameField"
                  id="requester-contact"
                  value={userName}
                  readOnly={true}         
                />
              </Grid>

              <Grid item sm={12} md={6}>
                <label htmlFor="requester-email" style={{ marginBottom: '8px', display: 'block',color:"#101828" }}>
                  Requester Email
                </label>
                <Field
                  name="requesterEmail"
                  as={TextField}
                  variant="outlined"
                  fullWidth
                  value={userEmail}
                  className="emailField"
                  id="requester-email"
                  readOnly={true}
                />
              </Grid>
            </Grid>


          <DialogActions className="dialog-actions">
            <Button
              type="submit"
              disabled={props.isSubmitting}
              variant="contained"
              className="request-button"
              endIcon={<img src={arrowImg} />}
            >
              Request Quote
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
    <LoaderComponent open={loading} />
    </DialogContent>
    </Dialog>
  );
};

export default CreditDialog;
