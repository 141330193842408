export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const onBtnImage = require("../assets/onbtnimage.svg");
export const cardImg = require("../assets/cardImages.svg");
export const downloadImg = require("../assets/download.svg");
export const arrowImg = require("../assets/arrow.svg");
export const questionImg = require("../assets/questionImg.svg");
export const tableImg = require("../assets/tableImg.svg");
export const secondImg = require("../assets/secondImg.svg");
export const thirdImg = require("../assets/thirdImg.svg");
export const close = require("../assets/close.svg");
export const cricle = require("../assets/cricle.svg");

