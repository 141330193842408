import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface BarChartProps {
  chartData: { [key: string]: number };
  // isMonthly: boolean;
}


const BarChart: React.FC<BarChartProps> = ({ chartData }) => {
  const labels = Object.keys(chartData);
  const values = Object.values(chartData);
  const stepSize = values.some((value) => value > 10000) ? 5000 : 2000;
  const data :ChartData<"bar", number[], string> = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: labels.map((_, index) =>
          index % 2 === 0 ? "#0099F3" : "#F69366"
        ),
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 0.1,
        barPercentage: 0.5,
        maxBarThickness: 8,
        borderRadius: 10,
        borderSkipped: false,
      },
    ],
  };
  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
            font: {
              size: 14, 
              family: "Gotham-Medium1",
            },
            color: "#475467"
          },
          beginAtZero: true
      },
  


      y: {
        min: 0,
        offset: true,
        grid: {
          display: true,
          drawBorder: false,
          color: (context: {tick:{value:number}}) => {
            if (context.tick.value === 0) {
              return "rgba(0, 0, 0, 0)"; 
            }
            return "rgba(0, 0, 0, 0.1)";
          },
        },
  
        ticks: {
          // stepSize: stepSize,
          font: {
            size: 14, 
            family: "Gotham-Light", 
            weight: "325", 
          },
          color: "#475467",
          
        },
        beginAtZero:true,
      },
    },
    plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
      },
  };

  return  <div style={{marginTop:"20px" }}> 
  <Bar data={data} options={options} height={410}
      />
</div>;
};

export default BarChart;



