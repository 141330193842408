// Customizable Area Start
import React from "react";
import { Grid, Avatar, IconButton } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
export const configJSON = require("./config");
import ProfileWebController, { Props } from "./ProfileWebController";
import { uploadIcon } from "./assets";
import "../assets/profile.scss";
import PasswordResetForm from "./components/PasswordResetForm";
import GeneralProfileForm from "./components/GeneralProfileForm";
import SchoolDetailsForm from "./components/SchoolDetailsForm";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import {
  getCommaSeperator,
  getCredits,
  getTeacherData,
  getUserDetails,
  isValidUrl,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import StandardsComponent from "./components/StandardsComponent";

export default class Profile extends ProfileWebController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    let leadTeacher = getTeacherData()
    let userDeta = getUserDetails();
    let account_credits=userDeta?.account_credits?.data[0]?.attributes?.unused_earned_credits;
    return (
      <Grid container className="profile-container" test-id="profile-container">
        <Grid item container className="sub-container">
          <Grid item>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              className="icon-btn-1"
            >
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={this.onImageChange}
                className="filetype"
                id="group_image"
              />
              <Avatar
                src={this.state.profileImage}
                className="profile-avatar"
              ></Avatar>
              <img
                className={
                  isValidUrl(this.state.profileImage)
                    ? "profile-icon"
                    : "nonvalid-profile-icon"
                }
                src={uploadIcon}
              />
            </IconButton>
          </Grid>
          <Grid className="center-flex-col">
            <span className="name">
              {userDeta.first_name +
                " " +
                userDeta.last_name}
            </span>
            <span className="desc">
              {this.state.initialValuesSchoolDetails.schoolName}
            </span>
          </Grid>

          <div className="credit-label">
            <span className="credit-value">
             {userDeta.school==null?(account_credits || 0):getCommaSeperator(leadTeacher)}
            </span>
            <span className="credit-name title-medium">
              {getCredits(leadTeacher)}
            </span>
          </div>

          <Grid container item sm={12}>
            <GeneralProfileForm
              state={this.state}
              handleSubmit={this.handleSubmit}
            />
          </Grid>
          <Grid container item sm={12}>
            <StandardsComponent
              state={this.state}
              getStatus={this.getStatus}
              updateStateOfStandard={this.updateStateOfStandard}
              fetchMoreData={this.fetchMoreData}
            />
          </Grid>
          <Grid item sm={12}>
            <PasswordResetForm
              state={this.state}
              handleMouseDownPassword={this.handleMouseDownPassword}
              handleClickShowPassword={this.handleClickShowPassword}
              handleSubmit={this.handleChangePassword}
            />
          </Grid>
          <Grid item sm={12}>
            <SchoolDetailsForm
              state={this.state}
              handleSubmit={this.handleSubmit}
            />
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} />
      </Grid>
    );
  }
}
// Customizable Area End
