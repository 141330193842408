import React from 'react';
import { Modal, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomizedProgressBars from "./ProgressBar";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8,
    outline: 'none',
    width:"625px",
    height:"575px"
  },
  heading:{
    fontFamily:"Gotham",
    fontWeight:700,
    fontSize:30,
    color: "#242A32",
  },
  paragraph:{
    fontFamily:"Gotham-light",
    fontWeight:325,
    fontSize:14,
    marginBottom:"15px"
  },
  head:{
    display: 'flex',
    justifyContent:'space-between',
    alignItems:'center',
  },
  wrapdetails:{
    display: 'flex',
    justifyContent:'space-between',
    marginBottom:"15px"
  },
  wrappaydetails1:{
    width:'60%',
  },
  wrappaydetails2:{
    width:'40%',
  },
  progress:{
    marginBottom:15
  },
  accounts:{
    padding: "16px",
    border: "1px",
    borderRadius: "8px",
    background: "#F2F4F7"
  },
  progress_details:{
    display: "flex",
    justifyContent: "space-between"
  },
  btnhover:{
    '&:hover': {
      background: "transparent", 
    }
  },
  wrapbtn:{
  padding:"12px 24px 12px 24px",
  background: "#007DC6",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 700,
  color: "#FFFFFF",
  fontFamily: "Gotham",
  marginTop:25, 
  marginBottom:15, 
  width:"100%",
  '&:hover': {
      background: "#007DC6", 
    },
  },
}));

interface CustomWarpPayModalProps {
  open: boolean;
  handleClose: () => void;
  handleClick:()=>void;
  customerName: string;
  warpData?: {
    total_price?: string;
    account_number?: string;
    current_pay_terms_date?: string;
  } | null;
  total_price?: string;
  account_number?: string;
  current_pay_terms_date?: string;
  orderid:string | null;
}

const CustomWarpPayModal: React.FC<CustomWarpPayModalProps> = ({ open, handleClose, customerName,warpData,
  total_price,
  account_number,handleClick,
  current_pay_terms_date,orderid }) => {
  const classes = useStyles();
  const finalTotalPrice = total_price || warpData?.total_price || "$0.00";
  const finalAccountNumber = account_number || warpData?.account_number || "0000";
  const finalPayTermsDate = current_pay_terms_date || warpData?.current_pay_terms_date || "NA";
  const payTermsDate = finalPayTermsDate !== "NA" 
  ? new Date(new Date(finalPayTermsDate).setDate(new Date(finalPayTermsDate).getDate() - 27))
    .toISOString().split('T')[0]
  : "NA";
  
  const priceWith15Percent = (Number(finalTotalPrice) * 0.85).toFixed(2);
  const newprice = `$${priceWith15Percent}`;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="custom-warp-pay-modal"
      className={classes.modal}
    >
      <Box className={classes.paper}>
        <div className={classes.head}>
          <Typography variant="h6" component="h2" className={classes.heading}>
          <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.213 2.14026C18.9236 3.31593 21.6454 5.91689 24.545 9.72229C28.6734 16.6322 31.7259 23.6764 32.545 31.7223C31.555 32.3823 30.565 33.0423 29.545 33.7223C29.0835 33.3356 28.622 32.9489 28.1466 32.5504C22.9328 28.3876 17.793 25.9646 11.545 23.7223C7.14389 22.0978 4.94978 20.9084 2.54501 16.7223C1.61397 11.9722 1.87783 8.77687 4.48251 4.65979C7.99757 2.43598 10.1018 1.84709 14.213 2.14026Z" fill="#FFEAC8"/>
            <path d="M17.5469 6.72293C19.8839 9.62411 20.5594 11.0961 20.4844 14.8479C19.5469 17.7229 19.5469 17.7229 17.9219 19.6604C14.4254 21.2246 12.2 20.7014 8.54688 19.7229C6.10938 18.0354 6.10938 18.0354 4.54688 15.7229C4.29688 12.4729 4.29688 12.4729 4.54688 9.72293C5.20688 9.72293 5.86687 9.72293 6.54688 9.72293C6.77375 9.08355 7.00062 8.44418 7.23438 7.78543C8.54688 5.72293 8.54688 5.72293 10.9219 4.84793C13.9486 4.7038 15.112 4.93737 17.5469 6.72293Z" fill="#CE4C03"/>
            <path d="M14.3874 2.05099C17.0958 2.89492 17.9451 3.83695 19.5436 6.16037C21.8245 10.5418 22.419 14.8302 22.5436 19.7229C20.5636 20.0529 18.5836 20.3829 16.5436 20.7229C16.5436 21.7129 16.5436 22.7029 16.5436 23.7229C11.5435 23.7011 8.44719 23.4605 4.48111 20.2229C2.19506 16.9173 1.80995 13.8098 2.07095 9.84787C2.54361 7.72287 2.54361 7.72287 4.48111 4.66037C8.01032 2.4276 10.2502 1.80276 14.3874 2.05099ZM7.54361 6.72287C7.21361 7.71287 6.88361 8.70287 6.54361 9.72287C5.88361 9.72287 5.22361 9.72287 4.54361 9.72287C4.04095 12.5113 4.04095 12.5113 4.54361 15.7229C6.67123 18.1619 7.74886 19.3865 10.5436 20.7229C14.2316 21.0654 14.2316 21.0654 17.5436 19.7229C19.8497 17.1285 20.568 15.6971 20.4811 12.2229C19.3277 9.14717 17.7904 7.10577 15.5436 4.72287C12.585 4.72287 10.2699 5.62073 7.54361 6.72287Z" fill="#FCA487"/>
            <path d="M17.5469 6.72375C18.7969 8.84875 18.7969 8.84875 19.5469 10.7238C15.6371 13.2598 13.6258 13.8252 8.98438 13.5988C6.36373 13.4603 6.36373 13.4603 4.54688 15.7238C4.54688 13.7438 4.54688 11.7638 4.54688 9.72375C5.20688 9.72375 5.86687 9.72375 6.54688 9.72375C6.77375 9.08438 7.00062 8.445 7.23438 7.78625C8.54688 5.72375 8.54688 5.72375 10.9219 4.84875C13.9046 4.70672 15.1395 4.98048 17.5469 6.72375Z" fill="#F85D02"/>
            <path d="M16.5469 3.72266C22.7696 6.38954 25.1576 10.5406 27.8125 16.5039C28.5469 18.7227 28.5469 18.7227 28.5469 22.7227C27.8869 22.7227 27.2269 22.7227 26.5469 22.7227C26.2169 21.4027 25.8869 20.0827 25.5469 18.7227C24.2269 18.7227 22.9069 18.7227 21.5469 18.7227C21.4373 18.0588 21.3277 17.3949 21.2148 16.7109C20.3355 11.8139 19.5524 7.82104 16.5469 3.72266Z" fill="#FEC798"/>
            <path d="M23.5469 19.7242C24.2069 21.0442 24.8669 22.3642 25.5469 23.7242C24.8869 23.7242 24.2269 23.7242 23.5469 23.7242C23.8769 25.0442 24.2069 26.3642 24.5469 27.7242C20.9891 27.1143 17.864 26.1459 14.5469 24.7242C15.2069 24.3942 15.8669 24.0642 16.5469 23.7242C16.5469 22.7342 16.5469 21.7442 16.5469 20.7242C19.0097 19.4929 20.8264 19.6527 23.5469 19.7242Z" fill="#FFCD9C"/>
            <path d="M5.54688 12.7227C6.84625 13.4033 6.84625 13.4033 8.17188 14.0977C11.2616 15.5853 14.2762 16.7103 17.5469 17.7227C17.5469 18.3827 17.5469 19.0427 17.5469 19.7227C11.3459 21.1817 11.3459 21.1817 7.60938 19.0352C5.54688 16.7227 5.54688 16.7227 5.54688 12.7227Z" fill="#B9440D"/>
            <path d="M16 5C15.67 6.65 15.34 8.3 15 10C12.03 9.505 12.03 9.505 9 9C9.3125 7.0625 9.3125 7.0625 10 5C13 4 13 4 16 5Z" fill="#FC6803"/>
            <path d="M13.375 1.3125C17.205 2.31559 18.1877 4.68274 20.1797 7.95703C21 10 21 10 20 13C19.7203 12.4225 19.4405 11.845 19.1523 11.25C17.9088 8.94684 16.7381 6.96181 15 5C11.2874 4.33076 11.2874 4.33076 8 5C9.67658 2.88222 10.7552 2.07085 13.375 1.3125Z" fill="#F78E5A"/>
            <path d="M22 17C22.99 17.33 23.98 17.66 25 18C25.6875 20.0625 25.6875 20.0625 26 22C26.66 20.68 27.32 19.36 28 18C30.1519 21.2279 30.2009 22.284 30 26C27.625 25.8125 27.625 25.8125 25 25C23.75 22.625 23.75 22.625 23 20C22.67 19.01 22.34 18.02 22 17Z" fill="#FFE0B8"/>
            <path d="M20 13C20.33 13 20.66 13 21 13C21.33 14.98 21.66 16.96 22 19C20.02 19.33 18.04 19.66 16 20C16 20.99 16 21.98 16 23C15.01 23 14.02 23 13 23C12.67 22.01 12.34 21.02 12 20C12.9488 19.5875 13.8975 19.175 14.875 18.75C18.0072 16.996 18.865 16.2915 20 13Z" fill="#F8A96F"/>
            <path d="M5 18C8.37463 18.5472 10.0824 19.0549 13 21C13 21.66 13 22.32 13 23C9.5625 22.1875 9.5625 22.1875 6 21C5.67 20.01 5.34 19.02 5 18Z" fill="#FABC9F"/>
            <path d="M29 27C29.99 27.33 30.98 27.66 32 28C31.67 29.32 31.34 30.64 31 32C30.01 32 29.02 32 28 32C27.34 31.34 26.68 30.68 26 30C26.99 29.01 27.98 28.02 29 27Z" fill="#FFF9ED"/>
            <path d="M2.00001 11C2.66001 11 3.32001 11 4.00001 11C4.30939 11.7837 4.61876 12.5675 4.93751 13.375C5.92876 15.9233 5.92877 15.9233 7.00001 18C3.12501 17.125 3.12501 17.125 2.00001 16C1.95938 14.3338 1.95729 12.6661 2.00001 11Z" fill="#F89B7B"/>
            <path d="M6 8C6.10312 8.63937 6.20625 9.27875 6.3125 9.9375C6.53938 10.6181 6.76625 11.2987 7 12C7.99 12.33 8.98 12.66 10 13C8.68 13 7.36 13 6 13C5.34 13.66 4.68 14.32 4 15C4 13.02 4 11.04 4 9C4.66 8.67 5.32 8.34 6 8Z" fill="#E35A19"/>
          </svg> Get Paid at Warp Speed 
          </Typography>
          <Box mt={2}>
            <Button className={classes.btnhover} onClick={handleClose} style={{fontSize: "x-large",justifyContent:"end",
              display: "flex"}}>
              X
            </Button>
          </Box>
        </div>
        <Typography className={classes.paragraph}>
           Your payment from <span style={{color:"rgb(46, 54, 66)",fontFamily:"Gotham",fontWeight:700}}>Betabox, Inc.</span> is currently expected by <span style={{color:"rgb(46, 54, 66)",fontFamily:"Gotham",fontWeight:700}}>{finalPayTermsDate}</span> based on this customers payment history. With <span style={{color:"#007DC6",fontFamily:"Gotham",fontWeight:700}}>Classbox WarpPay</span>, accelerate this receivable to access these funds quickly for a <span style={{color:"rgb(46, 54, 66)",fontFamily:"Gotham",fontWeight:700}}>15%</span> fee.  
        </Typography>
        <div className={classes.progress}>
          <div style={{marginBottom:"10px"}}>
            <CustomizedProgressBars/>
          </div>
          <div className={classes.progress_details}>
            <span><span style={{color:"#007DC6"}}>3 days</span> {payTermsDate}</span>
            <span>30 days </span>
            <span>{finalPayTermsDate}</span>
          </div>
        </div>
        <div className={classes.wrapdetails}>
          <div className={classes.wrappaydetails1}>
            <Typography variant="h6" component="h2" style={{fontFamily:"Gotham",fontSize:"16px",fontWeight:350}}>
             <li style={{color:"black"}}>WarpPay Terms</li>
            </Typography>
            <span><span  style={{color:"#007DC6"}}>3 days</span> | {payTermsDate}</span><br></br>
            <span style={{fontFamily:"Gotham",fontSize:"24px",fontWeight:700,color:"black"}}>{newprice}</span>
          </div>
          <div className={classes.wrappaydetails2}>
            <Typography variant="h6" component="h2" style={{fontFamily:"Gotham",fontSize:"16px",fontWeight:350}} >
             <li style={{color:"black"}}>Current Pay Terms</li>
            </Typography>
            <span>30 days | {finalPayTermsDate}</span><br></br>
            <span  style={{fontFamily:"Gotham",fontSize:"24px",fontWeight:700,color:"#475467"}}>${finalTotalPrice}</span>
          </div>
        </div>
        <div>
          <Box className={classes.accounts}>
              <span style={{color:"#242A32",fontSize:"16px",fontWeight:"bold",fontFamily:"Gotham-light"}}>Account: **{finalAccountNumber}</span>
            <Typography variant="h6" component="h2" style={{fontFamily:"Gotham-light"}}>
              Phasedock, Inc.
            </Typography>
          </Box>
        </div>
        <Button onClick={handleClick} className={classes.wrapbtn}>
          Confirm WarpPay for Order #{orderid}
        </Button>
        <Typography style={{fontFamily:"Gotham-light",fontSize:"14px",fontWeight:325}} >
          By clicking confirm, you agree to assign the PO for order #{orderid} to Betabox, Inc. <a style={{color:"#007DC6",textDecoration:"none"}} target='blank' href='/vendors/disclosures'>See full WarpPay disclosure here.</a>
        </Typography>
      </Box>
    </Modal>
  );
};

export default CustomWarpPayModal;