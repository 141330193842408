import React from "react";

import {
  Box,
  Typography,
  Grid,
  CardContent,

  // Customizable Area Start
  Button,
  Tooltip,
  // Customizable Area End
} from "@material-ui/core";
import { Products } from "./InventoryTypes";
// Customizable Area Start
import { closeIcon, imgIcon, titleIcon } from "./assets";
import { styled  } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "../assets/index.scss";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import InventoryManagementController, {
  Props,
  configJSON,
} from "./InventoryManagementController";

export default class InventoryManagement extends InventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <InventoryManagementCss>
          <Box className="titleBox">
            <img src={titleIcon} className="titleIcon"/>
          </Box>
          {this.state.closeSnakebar === true && <Box className="snakebarBoxMain">
            <Box className="snakebarBox">
              <Typography className="snakebarText">Do these listings need to be changed? Contact your Classbox manager.</Typography>
            </Box>
            <Box className="snakebarBtnBox">
              <Button data-test-id="onclickEmailID" className="snakebarBtn" onClick={this.onclickEmail}>Contact manager</Button>
              <img src={closeIcon} data-test-id="onClickCloseSnakebarID" className="closeIcon" onClick={this.onClickCloseSnakebar}/>
            </Box>
          </Box>}
          <Box>
            <Grid container xs={12} spacing={3}>
              {this.state.productData.map((value,index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} >
                  <Box className="productBoxMain">
                    <Box className="productImgBox">
                      {value.attributes.product_image 
                      ?<img src={value.attributes.product_image} className="productImg" />
                      :<img src={imgIcon}  />}
                      {value.attributes.sku_status === "published" ?
                      <Box className="tagPublishedBox">
                        <Box className="tagDot"></Box>
                        <Typography className="tagText">Available</Typography>
                      </Box> : 
                        <Box className="tagHiddenBox">
                          <Box className="tagDotHidden"></Box>
                          <Typography className="tagText">Unavailable</Typography>
                        </Box>
                      }
                    </Box>
                    <Box className="detailsMainBox">
                      <Box className="productTitleBox">
                      <Tooltip 
                      title={value.attributes.product_name} 
                      arrow 
                      classes={{ tooltip: 'custom-tooltip' }}
                      >
                          <Typography className="productName">    
                            {value.attributes.product_name}
                          </Typography>
                      </Tooltip>
                        <Typography className="productPrice">${value.attributes.price_per_unit}/unit</Typography>
                      </Box>
                      <Box className="productDetailBox">
                        <Typography className="productDetail">{value.attributes.description}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </InventoryManagementCss>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const InventoryManagementCss = styled(Box)({
  display:"flex",
  flexDirection:"column",
  gap:"20px",
  padding:"15px",
  "& .titleBox":{
    height:"50px",
    width:"100%",
    marginTop:"10px",
    display:"flex",
    alignItems:"center",
  },
  "& .titleIcon":{
    height:"30px",
    width:"auto",
  },
  "& .snakebarBoxMain":{
    height:"auto",
    width:"100%",
    backgroundColor:"#D2EFFF",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderRadius:"10px",
    padding:"15px",
  },
  "& .snakebarBox":{
    display:"flex",
    justifyContent:"flex-start",
    alignItems:"center",
  },
  "& .snakebarText":{
    fontWeight:400,
    fontFamily: '"Gotham-Light", "Courier New", Courier, monospace',
    lineHeight:"18px",
    color:"#101828",
  },
  "& .snakebarBtnBox":{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:"20px",
  },
  "& .snakebarBtn":{
    color:"#FFFFFF",
    backgroundColor:"#007DC6",
    height:"40px",
    width:"170px",
    borderRadius:"10px",
    fontWeight:500,
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace !important',
    lineHeight:"24px",
    fontSize:"14px",
  },
  "& .closeIcon":{
    margin:"15px",
    height:"14px",
    width:"14px",
    cursor:"pointer",
  },
  "& .productBoxMain":{
    height:"auto",
    maxWidth:"100%",
    display:"flex",
    flexDirection:"column",
    gap:"30px",
  },
  "& .productImgBox":{
    position:"relative",
    width:"100%",
    height:"290px",
    backgroundColor:"#F1F1F1",
    borderRadius:"15px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .productImg":{
    height:"100%",
    width:"100%",
    borderRadius:"15px",
  },
  "& .detailsMainBox":{
    display:"flex",
    flexDirection:"column",
    gap:"10px",
  },
  "& .productTitleBox":{
    display:"flex",
    justifyContent:"space-between",
    gap: "16px",
  },
  "& .productName":{
    fontSize:"16px",
    fontWeight:700,
    lineHeight:"24px",
    fontFamily:'"Gotham-Bold", "Courier New", Courier, monospace !important',
    color:"#101828", 
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .productPrice":{
    fontSize:"14px",
    fontWeight:500,
    lineHeight:"20px",
    fontFamily:'"Gotham-Medium1", "Courier New", Courier, monospace !important',
    color:"#344054",
    whiteSpace: "nowrap", 
    flexShrink: 0,
  },
  "& .productDetailBox":{

  },
  "& .productDetail":{
    fontSize:"14px",
    fontWeight:"bold !important",
    lineHeight:"20px",
    fontFamily:'"Gotham-Light", "Courier New", Courier, monospace !important',
    color:"#475467",
  },
  "& .tagPublishedBox":{
    position:"absolute",
    top:"15px",
    right:"15px",
    width:"auto",
    height:"30px",
    backgroundColor:"#A6DEFF",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    gap:"15px",
    padding:"8px",
    borderRadius:"20px",
  },
  "& .tagDot":{
    width:"10px",
    height:"10px",
    backgroundColor:"#007DC6",
    borderRadius:"100%",
  },
  "& .tagDotHidden":{
    width:"10px",
    borderRadius:"100%",
    backgroundColor:"#242A32",
    height:"10px",
  },
  "& .tagText":{
    fontSize:"12px !important",
    fontWeight:"bold !important",
    lineHeight:"18px",
    fontFamily:'"Gotham-Light", "Courier New", Courier, monospace !important',
    color:"#001928",
  },
  "& .tagHiddenBox":{
    position:"absolute",
    height:"30px",
    backgroundColor:"#D0D5DD",
    display:"flex",
    top:"15px",
    justifyContent:"center",
    alignItems:"center",
    right:"15px",
    width:"auto",
    padding:"8px",
    borderRadius:"20px",
    gap:"15px",
  },
})
// Customizable Area End
