// Customizable Area Start
import React from "react";
import { Grid, Button,Box ,Typography} from "@material-ui/core";
export const configJSON = require("./config");
import RequestSelfController, {
  Props,
} from "./TeacherSelfOnboardingController";
import "../assets/teacher-onboarding.scss";



export default class TeacherOnboarding extends RequestSelfController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { selectedSchoolName } = this.state;
    return (
      <Grid container className="invite-teacher-container" style={{height:"auto",flex:1}}>
        <Box className="" style={{width:"635px",display:"block",margin:"auto",position:"relative"}}>
            <div className="clicle" style={{display:"flex",justifyContent:"center",width:"68px",height:"68px",background:"#ECFDF3",padding:"7px",borderRadius:"50%",position:"absolute",left:"50%",right:"1",top:"0", transform: "translate(-50%,-50%)",zIndex:1}}>
                <span><svg style={{width:"100%",height:"100%"}} width="60" height="59" viewBox="0 0 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9999 29.4987L25.9999 37.4987L41.9999 21.4987M56.6666 29.4987C56.6666 44.2262 44.7274 56.1654 29.9999 56.1654C15.2723 56.1654 3.33325 44.2262 3.33325 29.4987C3.33325 14.7711 15.2723 2.83203 29.9999 2.83203C44.7274 2.83203 56.6666 14.7711 56.6666 29.4987Z" stroke="#05603A" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </div>
            <div style={{background:"#ECFDF3",padding:"56px 20px 24px 20px",borderRadius:"16px 16px 0px 0px",position:"relative"}}>
                <Typography style={{textAlign:"center",color:"#05603A",fontFamily:"gotham"}}>Request Sent</Typography>
                <span style={{color:"#027A48",fontFamily:"Gotham-light"}}>Your request to join <span style={{color:"#027A48" ,fontFamily:"Gotham"}}>{selectedSchoolName}</span> has been sent to the school's Lead. You will </span>
                <span style={{color:"#027A48",fontFamily:"Gotham-light"}}>receive a notification once your request is reviewed. While you wait, click below </span>
                <span style={{display:"flex",justifyContent:"center",color:"#027A48",fontFamily:"Gotham-light"}}>to continue setting up your Classbox account</span>
            </div>
            <div style={{background:"white",padding:"24px 20px 24px 20px",borderRadius:"0px 0px 16px 16px"}}>
                <Button  onClick={this.handleLogin}  style={{background:"#007DC6",width:"100%",borderRadius:"10px",padding:"12px 24px 12px 24px"}}>
                    <div style={{display:"flex",gap:"20px",alignItems:"center"}}>
                    <div style={{color:"white",fontFamily:"gotham-medium"}}>Proceed To Onboarding</div>
                    <div>
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 7.5H17M17 7.5L11 1.5M17 7.5L11 13.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                    </div>
                </Button>
            </div>
        </Box>
      </Grid>
    );
  }
}
// Customizable Area End
