import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  token: string;
  productData: ProductSKUList;
  mailID:string;
  productGmail:string;
  gmailID:string;
  closeSnakebar:boolean;
  ideEmail:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface ProductSKUAttributes {
  id: number;
  product_name: string;
  sku_status: string;
  price_per_unit: number;
  description: string;
  product_image: string;
}

interface ProductSKU {
  id: string;
  type: string;
  attributes: ProductSKUAttributes;
}

type ProductSKUList = ProductSKU[];
  // Customizable Area End

export default class InventoryManagementController extends BlockComponent<
  Props,
  S,
  SS


> {
  // Customizable Area Start
  getProductsApiCallId = "";
  getProductsGmailApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      token: "",
      txtSavedValue: "",
      mailID:"",
      enableField: false,
      productData: [],
      productGmail:"",
      gmailID:"",
      closeSnakebar:true,
      ideEmail:6,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getInventoryProduct();
    this.getInventoryGmail();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getProductsApiCallId) {
          if (responseJson.data.length != 0) {
            this.setState({ productData: responseJson.data,mailID: responseJson.data[0].id});
            this.getInventoryGmail();
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.getProductsGmailApiCallId) {
          this.setState({ productGmail: responseJson.gmail_url
           });
      }
      }
    }

    // Customizable Area End
  }

  onclickEmail = () => {
    window.location.href = this.state.productGmail;
  }
  onClickCloseSnakebar = () => {
    this.setState({
      closeSnakebar : false
    })
  }

  getInventoryProduct = () => {
    let token = getAuthToken();
    const PCheaders = {
      token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InventoryProductEndPoint
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
  };

  getInventoryGmail = () => {
    let token = getAuthToken();
    const PCheaders = {
      token,
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsGmailApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.InventoryMailEndpoint}${this.state.mailID}/:id/open_mail`
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
  };

  // Customizable Area End
}
