import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleTokenError } from "../../../components/src/NativeWebRouteWrapper/Utils";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";



export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}
export interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
}

interface OrderAttributes {
  order_status: string;
  quantity: number;
  status: string;
  cost: number | null;
  classbox_id: number;
  account_id: number | null;
  payment_status: string;
  total_price: number;
  classbox_name: string;
  customer: Customer;
  shipped_from: string;
}
interface Customer {
  id: number;
  name: string;
}

interface OrderDetails {
  id: string;
  type: string;
  attributes: OrderAttributes;
}
interface Ordercard {
  id: number | null;
  payment_date: string;
  credit_price: number;
}


interface S {
  isMonthly: boolean;
  isDropdownOpen: boolean;
  isData:boolean;
  orderData:OrderDetails[];
  loading: boolean;
  chartData:{
    orders_graph_data:{
    Jan: number;
    Feb: number;
    Mar: number;
    Apr: number;
    May: number;
    Jun: number;
    Jul: number;
    Aug: number;
    Sep: number;
    Oct: number;
    Nov: number;
    Dec: number;
  }
    future_income_orders: Ordercard[];
    recent_payouts_orders: Ordercard[];
    yearly_total_credit: number;
  },
  chartStatus:string,
  isFilterOpen: boolean;
  anchorEl: HTMLElement | null;
  activeFilterType:string
  selectedFilters: {
    notshipped: boolean;
    shipped: boolean;
    delivered: boolean;
    delayed:boolean;
    notpaid:boolean;
    paid:boolean;
    accelerated:boolean;
  };
  activeFilterLabels: string[],
  isFilterLoading: boolean,
  perPage:number,
  isFiltered:boolean,
  hasMore: boolean,
  currentPage: number,
  isLoadingMore: boolean
}
interface SS {
  id: any;
}

export default class VenodrDashboardController extends BlockComponent<Props, S, SS> {
  apiGetQueryStrinurl: string = "";
  getOrderDataId:string="";
  getChartDataId:string="";
  filterDataApiCallId: string = "";
  public filterRef: HTMLDivElement | null = null;
  public dropdownRef: React.RefObject<HTMLDivElement>;
  scrollData: React.RefObject<HTMLDivElement>;
  public mounted: boolean = false;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.setFilterRef = this.setFilterRef.bind(this);
    this.handleClickOut = this.handleClickOut.bind(this);
    this.dropdownRef = React.createRef();
    this.scrollData = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      isMonthly: true,
      isDropdownOpen: false,
      isData:true,
      loading:false,
      orderData:[],
      chartStatus:"monthly",
      isFilterOpen: false,
      anchorEl: null,
      activeFilterType:"",
      selectedFilters: {
        notshipped: true,
        shipped: false,   
        delivered: false,
        delayed:false,
        notpaid:false,
        paid:false,
        accelerated:false,
      },
      activeFilterLabels: [],
      isFilterLoading: false,
      currentPage: 1,
      hasMore: true,
      isLoadingMore: false,
      perPage: 5,
      isFiltered: true,
      chartData:{
      orders_graph_data:
      {
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      },
      future_income_orders: [
        {
          id: null,
          payment_date: "",
          credit_price: 0,
        },
        {
          id: null,
          payment_date: "",
          credit_price: 0,
        },
      ],
      recent_payouts_orders: [
        {
          id: null,
          payment_date: "",
          credit_price: 0,
        },
        {
          id: null,
          payment_date: "",
          credit_price: 0,
        },
      ],
      yearly_total_credit: 0,
    }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.mounted = true;
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOut);
    window.addEventListener("scroll", this.handleScroll, true);
    this.filterDataApi(this.state.selectedFilters, 1);
    this.getToken();
    this.getChartData(this.state.chartStatus);
  }
  async componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("scroll", this.handleScroll, true);
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOut);
  }
  setFilterRef = (ref: HTMLDivElement | null) => {
    this.filterRef = ref;
  };
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        handleTokenError(responseJson);
        if (responseJson) {
          this.setState({
            loading: false,
            isLoadingMore: false,
            isFilterLoading: false,
          });
        }
        if (responseJson && !responseJson.errors) {
          if (apiRequestCallId === this.getOrderDataId || apiRequestCallId === this.filterDataApiCallId) {
            const newData = responseJson.data;            
            if (this.mounted) {
              this.setState((prevState) => ({
                orderData: prevState.currentPage === 1 ? newData : [...prevState.orderData, ...newData],
                currentPage: prevState.currentPage + 1,
                hasMore: newData.length === this.state.perPage,
              }));
            }
          }
           if(apiRequestCallId === this.getChartDataId)
            {
             this.setState({
              chartData:responseJson
             })
            }
        }
        else{
          if (apiRequestCallId === this.filterDataApiCallId || apiRequestCallId === this.getOrderDataId) {
            if (responseJson.errors[0].order === "orders not found") {
              this.setState({
                hasMore: false,
                orderData: this.state.currentPage === 1 ? [] : this.state.orderData,
              });
            }
          }
        }
      }
      }
      handleToggle = () => {
        this.setState((prevState) => ({
          isDropdownOpen: !prevState.isDropdownOpen,
        }));
      };
    
      handleOptionClick = () => {
        this.setState(
          (prevState) => ({
            isMonthly: !prevState.isMonthly,
            isDropdownOpen: false,
            chartStatus: prevState.isMonthly ? "yearly" : "monthly",
          }),
          () => {
            this.getChartData(this.state.chartStatus);
          }
        );
      };
      handleClickOutside = (event: MouseEvent): void => {
        if (
          this.filterRef && 
          event.target instanceof Node && 
          !this.filterRef.contains(event.target) && 
          this.state.isFilterOpen
        ) {
          this.setState({ isFilterOpen: false });
        }
      }
      handleClickOut(event:MouseEvent) {
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) {
          this.setState({ isDropdownOpen: false });
        }
      }
      filterDataApi = async (filters: { [key: string]: boolean }, page: number) => {
        if (!this.mounted) return;
    
        const queryParams = Object.entries(filters)
          .filter(([_, value]) => value)
          .map(([key]) => `${key}=true`);
    
        const finalQueryParams = [
          ...queryParams,
          `page=${page}`,
          `per_page=${this.state.perPage}`
        ].join('&');
    
        const endPoint = `${configJSON.filterEndPonit}?${finalQueryParams}`;
    
        this.setState({ isFilterLoading: true });
    
        this.filterDataApiCallId = await this.apiCall({
          contentType: configJSON.filterDataContentType,
          method: configJSON.filterDataMethod,
          endPoint: endPoint
        });
      };
      handleScroll = () => {
        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 300;
    
        if (scrolledToBottom && !this.state.isLoadingMore && this.state.hasMore) {
          this.loadMoreData();
        }
      };
      loadMoreData = () => {
        if (this.state.isLoadingMore || !this.state.hasMore || !this.mounted) return;
    
        this.setState({ isLoadingMore: true }, () => {
          if (this.state.isFiltered) {
            this.filterDataApi(this.state.selectedFilters, this.state.currentPage);
          } else {
            this.getOrderData(this.state.currentPage);
          }
        });
      };
      getOrderData = async (page: number) => {
        if (!this.mounted) return;
    
        const token = await getStorageData("authToken");
        const header = {
          token: token,
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getOrderDataId = requestMessage.messageId;
    
        const endpoint = `${configJSON.orderDataEndPoint}?page=${page}&per_page=${this.state.perPage}`;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        this.setState({ isLoadingMore: true });
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      apiCall = async (apiData: ApiCallInterface) => {
        const token = await getStorageData("authToken");
        const { contentType, method, endPoint } = apiData;
        const header = {
          "Content-Type": contentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
      getChartData =async (chartstatus:string) => {
        const token = await getStorageData("authToken");
        const header = {
          token: token,
        };
        const requestCompanyData = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getChartDataId = requestCompanyData.messageId;
        requestCompanyData.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
         `${configJSON.chartEndPoint}${chartstatus}`
          
        );
        requestCompanyData.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestCompanyData.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        this.setState({
          loading: true,
        });
    
        runEngine.sendMessage(requestCompanyData.id, requestCompanyData);
        return true;
      };
    
      
  }

 

// Customizable Area End
