import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import ConfirmDialog from "./ComformDialog";
import { delet, line} from "../../blocks/TeamBuilder/src/assets";
import { Data } from './TableComponent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      padding: "9px 0px 9px 13px",
      height: "105px",
      background: "#FFFFFF",
      minWidth: "188px"
    },
    paperHeight:{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: "60px",
      background: "#FFFFFF",
      minWidth: "250px"
    },
    blue: {
      fontStyle: "normal",
      fontWeight: 350,
      fontSize: "15px",
      lineHeight: "20px",
      color: 'var(--Gray-600, #475467)',
      cursor: 'pointer',
      fontFamily: "Gotham-medium"
    },
    red: {
      fontStyle: "normal",
      fontWeight: 350,
      fontSize: "15px", 
      lineHeight: "20px",
      color: "#F04438",
      cursor: 'pointer',
      fontFamily: "Gotham-medium"
    },
    customPopover:{
      boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.23) !important",
      borderRadius: "8px !important",
      border: "1px solid #80808047"
    },
  })
);

interface RowData {
  name: string;
  permission: string;
  earned: number;
  allocated: number;
  currentBalance: number;
  activated: boolean;
  image: string;
  teacherId: number;
  unused_earned_credits : number;
  currentUser: boolean;
  schoolName: string;
}

interface PopoverProps {
  anchorEl: null | HTMLElement | undefined;
  handleClose: () => void;
  rowData: RowData;
  handleOkAction? : (teacherId : number, permission:string) => void;
  popupToShow: string;
  handleRemoveActions : (teacherId : number)=> void;
  isPendingCheckoutShow? : boolean
  rows?: Data[];
}

const PopoverComponent : React.FC<PopoverProps>=({ rows, anchorEl, handleClose, rowData, handleOkAction, popupToShow, handleRemoveActions, isPendingCheckoutShow }) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openRemoveConfirmDialog, setOpenRemoveConfirmDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
    setOpenRemoveConfirmDialog(false);
    handleClose();
  };
// blue btn api call
  const handleOkClick = () => {
    if(handleOkAction){
      handleOkAction(rowData.teacherId, rowData.permission)
    }
    setOpenConfirmDialog(false);
    handleClose();
  };

  const handleClickOpenRemoveDialog = () => {
    setOpenRemoveConfirmDialog(true)
  }
// red btn api call
  const handleRemoveOkBtn = () => {
    handleRemoveActions(rowData.teacherId)
    setOpenRemoveConfirmDialog(false)
    handleClose();
  };

  const renderPopupOptions = () => {
   if(popupToShow === "removeLeadSelf"){
    return (
      <div className={classes.paperHeight}>
      <span data-test-id="removeLeadSelfTest" className={classes.red} onClick={handleClickOpenRemoveDialog}> <img src={delet} alt="Remove My Co-Lead Access" /> Remove My Co-Lead Access</span>
      </div>
    )
   } else if(popupToShow === "removeLeadOther"){
    return (
      <div className={classes.paperHeight}>
      <span className={classes.red} onClick={handleClickOpenRemoveDialog}> <img src={delet} alt="Remove Co-Lead Access" /> Remove Co-Lead Access</span>
      </div>
    )
   } else if(popupToShow === "leadAndRemove"){
    return (
      <>
      <div className={classes.paper}>
       <span data-test-id="coleadActionClick" className={classes.blue} onClick={handleClickOpenDialog}>
         Make a Co-Lead
       </span>
       <span><img src={line} alt="linecon" /></span>
       <span className={classes.red} onClick={handleClickOpenRemoveDialog}> <img src={delet} alt="Remove User" /> Remove User</span>
       </div>
     </>
    )
   }
  }

  const renderConfirmDialogues = () => {
    if(popupToShow === "removeLeadSelf"){
      const leadCount = rows?.filter(item => item.permission === "Lead").length || 0;
      if(leadCount > 1){
      return (
        <ConfirmDialog
          open={openRemoveConfirmDialog}
          onOkText="Remove My Co-Lead Permissions"
          onCancleText="Cancel"
          handleClose={handleCloseDialog}
          title="Remove Co-Lead Permissions"
          content={["You’re about to remove your Co-Lead permissions for ", <span className="bold-content-text">{rowData.schoolName}.</span>, " After doing so, you will no longer have access to Lead Mode for your school. If you will need to also be unlinked from ", <span className="bold-content-text">{rowData.schoolName},</span>, " reach out to a remaining Lead to complete this request"]}
          onOk={handleRemoveOkBtn}
          onCancle={handleCloseDialog}
          btnClass="ok-btn-red" iconUrl={""} />
      )
     }else{
      return(
        <ConfirmDialog
        open={openRemoveConfirmDialog}
        onOkText=""
        onCancleText="Cancel"  
        handleClose={handleCloseDialog}
        title="First, assign a new Lead"
        content={["Before removing yourself as a lead from ", <span className="bold-content-text">{rowData.schoolName},</span>, " you will first need to assign another colleague as the Lead for Classbox."]}
        btnClass="ok-btn-red" 
        iconUrl={""} 
        onCancle={handleCloseDialog}/>

      )
    }
  }
     else if (popupToShow === "removeLeadOther"){
      return (
        <ConfirmDialog
        open={openRemoveConfirmDialog}
        onOkText="Remove Co-Lead Permissions"
        onCancleText="Cancel"
        handleClose={handleCloseDialog}
        title="Remove Co-Lead Permissions"
        content={["You’re about to remove Co-Lead permissions for ", <span className="bold-content-text">{rowData.name}.</span>, " After doing so, they will no longer have access to Lead Mode for your school."]}
        onOk={handleRemoveOkBtn}
        onCancle={handleCloseDialog}
        warningText={rowData.name}
        iconUrl={rowData.image}
        userRole={rowData.permission}
        btnClass="ok-btn-red"
      />
      )
    } else if(popupToShow === "leadAndRemove"){
        {/* delete teacher - has credit popup */}
        if(!isPendingCheckoutShow){
          if(Number(rowData.allocated) > 0){
            return (
              <ConfirmDialog
              open={openRemoveConfirmDialog}
              onOkText="Reclaim Credits and Remove User"
              onCancleText="Cancel"
              handleClose={handleCloseDialog}
              title="Are you sure?"
              content={["You’re about to remove ", <span className="bold-content-text">{rowData.name}</span>, " from your school. This user will still have a Classbox account, but their ", <span className="bold-content-text">{rowData.allocated}</span>, " allocated credits will be reclaimed, and they will not longer have access to ", <span className="bold-content-text">{rowData.schoolName}.</span>]}
              onOk={handleRemoveOkBtn}
              onCancle={handleCloseDialog}
              warningText={rowData.name}
              iconUrl={rowData.image}
              userRole={rowData.permission}
              btnClass="ok-btn-red"
            />
            )
          } else {
            {/* delete teacher - no credit popup */}
            return (
              <ConfirmDialog
                open={openRemoveConfirmDialog}
                onOkText="Remove User"
                onCancleText="Cancel"
                handleClose={handleCloseDialog}
                title="Are you sure?"
                content={["You’re about to remove ", <span className="bold-content-text">{rowData.name}</span>, " from your school. This user will still have a Classbox account, they will not longer have access to ", <span className="bold-content-text">{rowData.schoolName}.</span>]}
                onOk={handleRemoveOkBtn}
                onCancle={handleCloseDialog}
                warningText={rowData.name}
                iconUrl={rowData.image}
                userRole={rowData.permission}
                btnClass="ok-btn-red"
              />
            )
          }
        }
    }
  }

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}      
        classes={{
          paper: classes.customPopover,
        }}
      > 
        <div>
          {/* rendering lead remove popups */}
          {
            renderPopupOptions()
          }
        </div>
      </Popover>
      <ConfirmDialog
        open={openConfirmDialog}
        onOkText="Give Co-Lead Permissions"
        onCancleText="Cancel"
        handleClose={handleCloseDialog}
        title="Do you want to make this user a Co-Lead for your school?"
        content={["Once this user is make a Co-Lead, they will be able to allocate credits, invite new users, and manage requests for ", <span className="bold-content-text">{rowData.schoolName}.</span>]}
        onOk={handleOkClick}
        onCancle={handleCloseDialog}
        warningText={rowData.name}
        iconUrl={rowData.image}
        userRole={rowData.permission}
        btnClass="ok-btn-blue"
      />

      {/* all remove confirm dialogues conditionally rendered */}
      {
        renderConfirmDialogues()
      }
    </>
  );
}

export default PopoverComponent;
