// Customizable Area Start
import React from "react";
import { Switch, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area Start
import StandardSelectionController, {
  Props,
} from "./StandardSelectionController";
import { logoApp,profile} from "./assets";
import { aeroIcon } from "../../invitefriends/src/assets";
import { LEFT } from "../../RolesPermissions/src/assets";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";

import "../assets/index.scss";
import { getTeacherName, getTrimTitle, getUserDetails } from "../../../components/src/NativeWebRouteWrapper/Utils";


// Customizable Area End

export default class StandardSelection extends StandardSelectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const user = getUserDetails();
    this.state = {
      ...this.state, 
      user: user,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  




  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="standards-selection-container">
        <img className="logo-icon" src={logoApp} alt="logo" />
        <div className="left-container">
          <span className="step-txt">{this.state.isLead ? "3/3" : "2/2"}</span>
          <span className="title-txt">Set My Standards</span>
          <span className="description">
            Take a moment to select the curriculum standards you need your
            lessons to align with.
          </span>
          <div className="btn-container-div">
            <Button className="back-btn-step" onClick={() => this.props.navigation.goBack()}>
              <img src={LEFT} alt="back" />
              &nbsp; &nbsp;Back
            </Button>
            &nbsp; &nbsp;&nbsp;
            <Button className="go-to-dashboard" onClick={this.goTODashboard}>
              Proceed to Dashboard &nbsp; &nbsp; <img src={aeroIcon} alt="go" />
            </Button>
          </div>
          <div className="recommendation-container">
            <div className="recommendation-text">
              Pre-selected standard sets are recommended by
            </div>
            <div className="recommendation-profile">
              <img src={this.state.user_image || profile} alt="teacher" className="teacher-img" />
              <div className="teacher-info">
                <span className="teacher-name">
                  {`${(this.state.leadDetails.first_name || "")} ${(this.state.leadDetails.last_name || "")}`.length > 15
                    ? `${(this.state.leadDetails.first_name)} ${(this.state.leadDetails.last_name)}`.substring(0, 20) + '...'
                    : `${(this.state.leadDetails.first_name || "")} ${(this.state.leadDetails.last_name || "")}`}
                </span>
                <span className="teacher-title">{this.state.leadDetails.lead_type || "administrator"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="right-container" id="right-container">
          <div className="infinite-scroll-container">
            <InfiniteScroll
              dataLength={this.state.standardsData.length}
              next={this.fetchMoreData}
              hasMore={this.state.page <= this.state.pagination.total_pages}
              loader={
                !this.state.loading ? (
                  <div className="more-loader">
                    <CircularProgress />
                  </div>
                ) : (
                  <></>
                )
              }
              className="cards-container"
              scrollableTarget="scrollableDiv"
              height={"100%"}
            >
              {this.state.standardsData.map((item: any) => {
                return (
                  <div className="card-selection" key={item.id}>
                    <div className="card-content">
                      <img
                        src={item.attributes.logo.url}
                        alt="profile"
                        className="profile-img"
                      />
                      <div className="profile-details">
                        <span className="standard-title">
                          {item.attributes.title}
                        </span>
                        <div className="tag-container">
                          {item.attributes.standards_count}{" "}
                          {item.attributes.standards_count <= 1
                            ? "Standard"
                            : "Standards"}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        item.attributes.account_added ? "selected" : "unselected"
                      }
                    >
                      <Switch
                        id="switch-btn"
                        checked={this.getStatus(item)}
                        onChange={() => this.updateStateOfStandard(item)}
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area End
