// Customizable Area Start
import React from "react";
import VenodrDashBoardWebController, { Props } from "./VendorDashboardController";
import {
    Grid,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    CircularProgress
  } from "@material-ui/core";
import "../assets/vendordashboard.scss";
import {
  View,
  StyleSheet,
  ScrollView,
  Image,
} from "react-native";
import { downArrow ,noData} from "./assets";
import { funnelIcon,titleIcon, deliveredPaidButton, deliveredWarpPayButton, deliveredAtWarpSpeedButton, shippedNotPaidButton, notShippedNotPaidButton } from "./assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { formatDateToMonth, getPaymentChip, getStatusChip } from "../../../components/src/utility";
import BarChart from "../../../components/src/Chart/BarChart";
import { getCommaSeperator } from "../../../components/src/NativeWebRouteWrapper/Utils";
type FilterKey = 'notshipped' | 'shipped' | 'delivered' | 'delayed' | 'notpaid' | 'paid' | 'accelerated';

export default class VendorDashboard extends VenodrDashBoardWebController {
  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.state,
      isFilterOpen: false,
      activeFilterType: 'order',
      selectedFilters: {
        notshipped: true,
        shipped: false,
        delivered: false,
        delayed: false,
        notpaid: false,
        paid: false,
        accelerated: false,
      },
      activeFilterLabels: ['order: notshipped'],
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleFilterClick = () => {
    this.setState(prevState => ({
      isFilterOpen: !prevState.isFilterOpen,
      activeFilterType: 'order',
    }));
  }
  handleFilterTypeClick = (type: 'order' | 'payment') => {
    this.setState({ activeFilterType: type });
  }
  handleFilterChange = (filterName: string) => {
    if (filterName in this.state.selectedFilters) {
      this.setState((prevState) => {
        const newFilters = {
          ...prevState.selectedFilters,
          [filterName]: !prevState.selectedFilters[filterName as FilterKey],
        };
        
        let newActiveFilterLabels = prevState.activeFilterLabels.filter(
          label => label.split(': ')[1] !== filterName
        );
        
        if (newFilters[filterName as FilterKey]) {
          newActiveFilterLabels.push(`${this.state.activeFilterType}: ${filterName}`);
        }
        
        return { 
          selectedFilters: newFilters,
          activeFilterLabels: newActiveFilterLabels,
          currentPage: 1,
          isFiltered: true,
          hasMore: true,
          orderData: [], 
          isFilterLoading: true
        };
      }, () => {
        this.filterDataApi(this.state.selectedFilters, 1);
      });
    } 
  };
  removeFilter = (labelToRemove: string) => {
    const [filterType, filterName] = labelToRemove.split(': ');
    this.setState(prevState => {
      const newFilters = {
        ...prevState.selectedFilters,
        [filterName]: false
      };
      const newActiveFilterLabels = prevState.activeFilterLabels.filter(
        label => label !== labelToRemove
      );
      const hasActiveFilters = Object.values(newFilters).some(value => value === true);
      return {
        selectedFilters: newFilters,
        activeFilterLabels: newActiveFilterLabels,
        isFiltered: hasActiveFilters,
        currentPage: 1, 
        orderData: [], 
        hasMore: true, 
        isFilterLoading: true 
      };
    }, () => {
      if (!this.state.isFiltered) {
        this.getOrderData(1);
      } else {
        this.filterDataApi(this.state.selectedFilters, 1);
      }
    });
  };
  renderFilterBlock = () => {
    const { isFilterOpen, activeFilterType, selectedFilters  } = this.state;
    if (!isFilterOpen) return null;

    return (
      <div className="filter_block" ref={this.setFilterRef}>
        <div className="filter_title">
          <div className="orders" data-testID="orderbtn" onClick={() => this.handleFilterTypeClick('order')}
            style={{
              background: activeFilterType === 'order' ? 'white' : '',
            }}
            >
            <span>
            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5399 3.25015C1.97265 2.61616 1.68902 2.29917 1.67833 2.02976C1.66903 1.79573 1.7696 1.57079 1.95021 1.42167C2.15812 1.25 2.58348 1.25 3.4342 1.25H14.5664C15.4171 1.25 15.8425 1.25 16.0504 1.42167C16.231 1.57079 16.3316 1.79573 16.3223 2.02976C16.3116 2.29917 16.0279 2.61616 15.4607 3.25015L11.181 8.0333C11.0679 8.15968 11.0114 8.2229 10.9711 8.29482C10.9353 8.35857 10.9091 8.42727 10.8932 8.4986C10.8753 8.57907 10.8753 8.6639 10.8753 8.83348V12.8438C10.8753 12.9904 10.8753 13.0638 10.8516 13.1272C10.8307 13.1832 10.7967 13.2334 10.7525 13.2736C10.7024 13.3191 10.6343 13.3464 10.4982 13.4009L7.94816 14.4209C7.67246 14.5311 7.53461 14.5863 7.424 14.5632C7.32724 14.5431 7.24234 14.4857 7.18774 14.4033C7.1253 14.3092 7.1253 14.1606 7.1253 13.8638V8.83348C7.1253 8.6639 7.1253 8.57907 7.10739 8.4986C7.0915 8.42727 7.06527 8.35857 7.02952 8.29482C6.9892 8.2229 6.93266 8.15968 6.81958 8.0333L2.5399 3.25015Z" stroke="#0099F3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span>
            Order Status
          </div>
          <div className="payments" data-testID="paybtn" onClick={() => this.handleFilterTypeClick('payment')}
             style={{
              background: activeFilterType === 'payment' ? 'white' : '',
            }}
          >
            <span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.57575 3C8.5227 2.07211 9.81953 1.5 11.25 1.5C14.1495 1.5 16.5 3.85051 16.5 6.75C16.5 8.18048 15.9279 9.47738 14.9999 10.4243M12 11.25C12 14.1495 9.6495 16.5 6.75 16.5C3.85051 16.5 1.5 14.1495 1.5 11.25C1.5 8.3505 3.85051 6 6.75 6C9.6495 6 12 8.3505 12 11.25Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span>
            Payment Status
          </div>
        </div>
        {activeFilterType === 'order' && (
          <div className="filter_values">
            <div className={`order_values ${selectedFilters.notshipped ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.notshipped}
                onChange={() => this.handleFilterChange('notshipped')}
              />
              <span>Not Shipped</span>
            </div>
            <div className={`order_values ${selectedFilters.shipped ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.shipped}
                onChange={() => this.handleFilterChange('shipped')}
              />
              <span>Shipped</span>
            </div>
            <div className={`order_values ${selectedFilters.delayed ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.delayed}
                onChange={() => this.handleFilterChange('delayed')}
              />
              <span>Delayed</span>
            </div>
            <div className={`order_values ${selectedFilters.delivered ? 'blueBack' : ''}`}>
              <input
                type="checkbox"
                checked={selectedFilters.delivered}
                onChange={() => this.handleFilterChange('delivered')}
              />
              <span>Delivered</span>
            </div>
          </div>
        )}
        {activeFilterType === 'payment' && (
          <div className="filter_values">
            <div className={`payments_value ${selectedFilters.notpaid ? 'blueBack' : ''}`}>
              <input
                datatest-Id="paidbtn1"
                type="checkbox"
                checked={selectedFilters.notpaid}
                onChange={() => this.handleFilterChange('notpaid')}
              />
              <span>Not Paid</span>
            </div>
            <div className={`payments_value ${selectedFilters.paid ? 'blueBack' : ''}`}>
              <input
               datatest-Id="paidbtn2"
                type="checkbox"
                checked={selectedFilters.paid}
                onChange={() => this.handleFilterChange('paid')}
              />
              <span>Paid</span>
            </div>
            <div className={`payments_value ${selectedFilters.accelerated ? 'blueBack' : ''}`}>
              <input
               datatest-Id="paidbtn3"
                type="checkbox"
                checked={selectedFilters.accelerated}
                onChange={() => this.handleFilterChange('accelerated')}
              />
              <span>Accelerated</span>
            </div>
          </div>
        )}
      </div>
    );
  }
  renderOrdersTable = () => {
    const {isFilterLoading,orderData,activeFilterLabels ,isLoadingMore, hasMore } = this.state;
    const capitalizeFirstLetter = (str: string): string => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
   
    return (
      <TableContainer component={Paper} ref={this.scrollData} onScroll={this.handleScroll}>
        <div
          style={{
            padding: "20px",
            paddingRight:"30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="orderDiv">
          <div style={{
            fontFamily: "Gotham",
            fontWeight: 500,
            fontSize: 24,
            lineHeight: "32px",
            color: "#344054"
          }}>
            Orders
          </div>
          <div className="orderLength">{this.state.orderData.length}</div>
          </div>
          <div className="filter">
          <Button
            variant="outlined"
            startIcon={<img src={funnelIcon} alt="Funnel Icon" style={{ width: '20px', height: '18px' }} />}
            endIcon={<KeyboardArrowDownIcon style={{width: '30px', height: '24px',color:'#667085'}}/>}
            style={{ 
            borderRadius: '20px', 
            backgroundColor:'#F9FAFB', 
            padding: '4px 16px',
            display:'flex',
            justifyContent:'space-between',
            border:'none',
          }}
          data-testId='Filter_btn'
          onClick={this.handleFilterClick}
          >
            <div  className="filter">Filter
            </div>
          </Button>
          {this.renderFilterBlock()}
          </div>
        </div>
        {activeFilterLabels.length > 0 && (
          <div className="filterstatus">
            {activeFilterLabels.map((label: string, index: number) => {
              const [filterType, filterValue] = label.split(': ');
              let displayValue = capitalizeFirstLetter(filterValue);
              if (filterValue.toLowerCase() === 'notshipped') {
                displayValue = 'Not Shipped';
              } else if (filterValue.toLowerCase() === 'notpaid') {
                displayValue = 'Not Paid';
              }
              
              const capitalizedFilterKey = capitalizeFirstLetter(filterType);
              
              return (
                <div key={index} className="filter-label-container">
                  <span className="filter-label">
                    {`${capitalizedFilterKey}: ${displayValue}`}
                    <button datatest-ID="removebtn" onClick={() => this.removeFilter(label)} className="remove-filter">×</button>
                  </span>
                </div>
              );
            })}
          </div>
        )}
        <Table className="orders-table"> 
          <TableHead>
            <TableRow className="table-row-headings" >
              <TableCell className="order-number" >Order Number</TableCell>
              <TableCell className="headings">Items QTY</TableCell>
              <TableCell className="headings">Total Price</TableCell>
              <TableCell className="headings">Customer</TableCell>
              <TableCell className="headings">Ship By Date</TableCell>
              <TableCell className="headings">Statuses</TableCell>
              <TableCell className="headings"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-test-id="tablebody">
          {isFilterLoading ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <div className="filter-loader">
                  <CircularProgress size={24} />
                </div>
              </TableCell>
            </TableRow>
          ) : orderData.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography style={{
                  fontFamily: "Gotham",
                  fontSize: 16,
                  color: "#667085",
                  padding: "20px 0",
                  height:"170px",
                  textAlign:"center"
                }}>
                  No records found
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            <>
            {orderData.map((order, index) => (
              <TableRow className="rowData" data-test-id="tableRow" key={index}>
                <TableCell className="orderNumber-values">#{order.id}</TableCell>
                <TableCell className="itemsQty-values">{order.attributes.quantity}</TableCell>
                <TableCell className="totalPrice-values" >{order.attributes.total_price}</TableCell>
                <TableCell className="customer-name" >Betabox</TableCell>
                <TableCell className="shipByDate" >{formatDateToMonth(order.attributes.shipped_from)}</TableCell>
                <TableCell className="status">
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <div style={{marginBottom:'8px'}} className="statusChip">
                      {getStatusChip(order.attributes.status)}
                    </div>
                    <div className="paymentChip">
                      {getPaymentChip(order.attributes.payment_status)}
                    </div>                   
                  </div>
                </TableCell>
                <TableCell className="status">
                  <div className="status-btns">
                  {order.attributes.payment_status === "notpaid" && order.attributes.status === "notshipped" ? (
                    <IconButton className="status-btn">
                      <Image source={notShippedNotPaidButton} style={{ width: 50, height: 50 }}/>
                    </IconButton>
                  ) : order.attributes.payment_status === "paid_via_warp_pay" ? (
                    <IconButton className="status-btn">
                      <Image source={deliveredWarpPayButton} style={{ width: 50, height: 50 }} />
                    </IconButton>
                  ) : order.attributes.payment_status === "notpaid" && order.attributes.status === "shipped"? (
                    <IconButton className="status-btn">
                      <Image source={shippedNotPaidButton} style={{ width: 50, height: 50 }}/>
                    </IconButton>
                  ):order.attributes.payment_status === "at_warp_speed" ? (
                    <IconButton className="status-btn">
                      <Image source={deliveredAtWarpSpeedButton} style={{ width: 50, height: 50 }} />
                    </IconButton>
                  ) :order.attributes.payment_status === "paid" ? (
                    <IconButton className="status-btn">
                      <Image source={deliveredPaidButton} style={{ width: 50, height: 50 }}/>
                    </IconButton>
                  ):
                  <IconButton className="status-btn">
                  <Image source={shippedNotPaidButton} style={{ width: 50, height: 50 }}/>
                </IconButton>}
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {isLoadingMore && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <div className="loading-more">
                    <CircularProgress size={24} />
                    <Typography style={{ marginLeft: 10 }}>Loading more orders...</Typography>
                  </div>
                </TableCell>
              </TableRow>
            )}
            </>
          )}
        </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    return (
        <>
        <Grid container spacing={5} className="headertxt">
        <Grid sm={12} item className="dashboardtitle" >
        Dashboard
       </Grid> 
       <Grid container spacing={5} style={{marginLeft:"16px",marginRight:"24px"}}>
        <Grid item className="chartcard" xs={8}>
            {
                Object.values(this.state.chartData.orders_graph_data).every(value => value === 0) ?
                (
                  <div>
                    <img src={noData}/>
                  </div>          
                ):
                (
                <>
                <div className="earningheading">Earnings analytics</div>
                <div className="chartheading">
                  <div className="totalearning">${getCommaSeperator(this.state.chartData.yearly_total_credit)}</div>
                  <div className="dropdown-container">
                 <div ref={this.dropdownRef}>
                <button className="dropdown-button" onClick={this.handleToggle}>
                  {this.state.isMonthly ? "Monthly" : "Yearly"}
                  <img src={downArrow} className="dropdown-icon" />
                </button>
                {this.state.isDropdownOpen && (
                  <ul className="dropdown-options">
                    <li className="dropdown-option" onClick={this.handleOptionClick}>
                      {this.state.isMonthly ? "Yearly" : "Monthly"}
                    </li>
                  </ul>
                )}
                </div>
              </div>
                </div> 
                <BarChart chartData={this.state.chartData.orders_graph_data}/>
                </>
             )}
        </Grid>
        <Grid item xs={4} className="payoutBox">
        <Grid item className="payoutcard" xs={12} >
        <div className="recentpayment">Recent Payouts</div>
        {this.state.chartData.recent_payouts_orders.length>0 ? (
            <>
            {this.state.chartData.recent_payouts_orders.map((payment, index) => (
          <div className="payment-box" key={index}>
          <p className="amount">+{payment.credit_price}$</p>
          <p className="payment-date">Payment date:{new Date(payment.payment_date).toLocaleDateString()}</p>
        </div>
         ))}
            </>
        ) : (
             <div className="nodatabox">No Pending first payout</div>
        )}
   
        </Grid>
        <Grid item className="payoutcard" style={{marginTop:"24px"}} xs={12}>
        <div className="recentpayment">Future income</div>
        {this.state.chartData.future_income_orders.length>0? (
            <>
          {this.state.chartData.future_income_orders.map((payment, index) => (
          <div className="payment-box" key={index}>
          <p className="amount">+{payment.credit_price}$</p>
          <p className="payment-date">Payment date:{new Date(payment.payment_date).toLocaleDateString()}</p>
        </div>
         ))}
            </>
        ) : (
            <div className="nodatabox">No Pending first order</div>
        )}
        </Grid>
        </Grid>
       </Grid>
       <ScrollView
        keyboardShouldPersistTaps="always"
        style={styles.container}
        testID="sales-reporting-title"
      >
       
        <View  >
          {this.renderOrdersTable()}
        </View>
      </ScrollView>
       </Grid>
      
        </>
    )
  }
}
const styles = StyleSheet.create({
  imageContainer: {
    marginVertical: 15,
    alignItems: "flex-start"
  },
  titleIcon: {
    width: 150,
    height: 50,
    resizeMode: 'contain',
  },
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#fff",
    marginTop :20
  },
  analyticsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  analyticsBox: {
    width: "32.5%",
    padding: 16,
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    alignItems: "flex-start",
    borderWidth: 2,
    borderColor: "#F2F4F7",
  },
  analyticsTitle: {
    fontSize: 24,
    fontWeight: "300",
    fontFamily: "Gotham, sans-serif",
    lineHeight: 32,
    color: "#344054"

  },
  analyticsValue: {
    fontFamily: "Gotham-Light, !important",
    fontWeight: "700",
    fontSize: 48,
    marginVertical: 15,
    lineHeight: 60,
    color: "#101828"
  },
  analyticsLink: {
    fontFamily: "Gotham-Light, sans-serif",
    fontWeight: "400",
    fontSize: 14,
    color: "#007DC6",
    textDecorationLine: "none",
  },

  statusContainer: {
    flexDirection: "row",
  },
  statusBadge: {
    backgroundColor: "#d3d3d3",
    borderRadius: 5,
    padding: 5,
    marginHorizontal: 2,
    fontSize: 12,
    textAlign: 'center',
  },
});

// Customizable Area End
