// Customizable Area Start
import React from "react";
import { Grid, Button,Box ,Typography} from "@material-ui/core";
export const configJSON = require("./config");
import TeacherSelfOnboardingController, {
  Props,
} from "./TeacherSelfOnboardingController";
import Switch from '@material-ui/core/Switch';
import "../assets/teacher-onboarding.scss";
import "../assets/teacherselfonboarding.scss";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';



export default class TeacherOnboarding extends TeacherSelfOnboardingController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {  isFirstSectionOpen, isSecondSectionOpen, selectedState, selectedDistrict, selectedSchool,isSchoolConfirmed, statesList ,  districtsList  } = this.state;
    const isFirstComplete = this.isFirstSectionComplete();
    const isSecondComplete = this.isSecondSectionComplete();
    const isBothComplete = this.isBothSectionsComplete();
    return (
      <Grid container className="invite-teacher-container">
        <Grid container item sm={6} className="left">
          <div className="content">
            <span className="step">{this.state.isnumber}/3</span>
            <span className="title">Welcome</span>
            <span className="title">to Classbox!</span>
            <span className="desc">Let's get you connected! Classbox works best</span>
            <span className="desc">when your account is linked to a school. Find</span>
            <span className="desc">your school to proceed to the next step.</span>
          </div>
        </Grid>
        <Grid container item sm={6} className="right-container" style={{marginTop:"150px"}}>
          <div>
            <h3>Connect School</h3>
            <div>
              <Box className="first-box">
                <div>
                  <div className="network">
                    <h3 >Choose your...</h3>
                    <span 
                      className="uparrow1" 
                      onClick={() => this.toggleSection('first')}
                      style={{ cursor: 'pointer', transform: isFirstSectionOpen ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 20L16 12L8 20" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                  </div>
                  <div style={{ display: isFirstSectionOpen ? 'block' : 'none', transition: 'all 0.3s' }}>
                    <Typography style={{fontSize:"0.9rem",color:"#667085"}}>
                      Start by selecting your state and district to display a list of schools. Look for the Classbox icon next to the school's name to see if they're part of our network.
                    </Typography>
                    <hr></hr>
                    <div className="dropdown">
                      <div className="state" >
                        <div>
                          <span>State</span>
                        </div>
                        <FormControl variant="outlined" style={{width:"100%"}}>
                          <Select
                          className="select-container"
                          style={{border:"1px solid gray", fontFamily:"Gotham-light",padding:"8px !important",borderRadius:"10px"}}
                          data-testid="state-input"
                            value={selectedState}
                            onChange={this.handleStateChange}
                          >
                            <MenuItem data-testid="state-menu" value="">
                              <em>Select State</em>
                            </MenuItem>
                            {statesList.map((state) => (
                              <MenuItem key={state.id} value={state.id}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="district">
                        <span>District</span>
                        <FormControl variant="outlined" style={{width:"100%"}}>
                          <Select
                           style={{border:"1px solid gray", fontFamily:"Gotham-light",padding:"8px !important",borderRadius:"10px"}}
                          data-testid="district-input"
                            value={selectedDistrict}
                            onChange={this.handleDistrictChange}
                            disabled={!selectedState}
                          >
                            <MenuItem  data-testid="district-menu"value="">
                              <em>Select District</em>
                            </MenuItem>
                            {districtsList.map((district) => (
                              <MenuItem key={district.id} value={district.id}>
                                {district.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
            <div>
              <Box className="second-box" style={{
                opacity: isFirstComplete ? 1 : 0.7,
                cursor: isFirstComplete ? "pointer" : "not-allowed"
              }}>
                <div>
                  <div className="network" >
                    <h3 >My School</h3>
                    <span 
                      className="uparrow2"
                      onClick={() => this.toggleSection('second')}
                      style={{ 
                        cursor: isFirstComplete ? 'pointer' : 'not-allowed', 
                        transform: isSecondSectionOpen ? 'rotate(0deg)' : 'rotate(180deg)', 
                        transition: 'transform 0.3s' 
                      }}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 20L16 12L8 20" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>
                  </div>
                  <div style={{ display: isSecondSectionOpen ? 'block' : 'none', transition: 'all 0.3s' }}>
                    {!isSchoolConfirmed ? (
                      <>
                       <hr style={{marginBottom:"32px"}}></hr>
                        <div className="consent" >
                          <Typography style={{color:"#101828",fontSize:"16px",fontWeight:350,fontFamily:"Gotham"}}>Show only schools already in Classbox</Typography>
                          <Switch
                            color="primary"
                            name="checkedB"
                            checked={this.state.showOnlyClassboxSchools}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </div>
                        <div className="dropdown2" >
                          <div className="state" style={{width:"100%"}}>
                          <FormControl variant="filled" style={{width:"100%",borderRadius:"10px"}} >
                            <select 
                              name="school" 
                              id="school" 
                              style={{ fontFamily:"Gotham-light",borderRadius:"10px",padding:"12px",background:"white",borderRight:"10px",border:"1px solid gray"}}  
                              value={selectedSchool}
                              onChange={this.handleSchoolChange}
                            >
                              <option value="">Select School</option>
                              {this.state.schoolsList.map((school) => (
                                <option key={school.id} value={school.id}>
                                  {school.name}
                                </option>
                              ))}
                            </select>
                          </FormControl>
                          </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"end"}}>
                          <Button 
                            style={{
                              padding:"12px 24px 12px 24px",
                              borderRadius:"10px",
                              color:"white",
                              fontFamily:"gotham",
                              fontWeight:350,
                              background: isSecondComplete ? "#007DC6" : "#B2B2B2",
                              cursor: isSecondComplete ? "pointer" : "not-allowed",
                            }}
                            disabled={!isSecondComplete}
                            onClick={this.handleConfirmSchool}
                          >
                            Confirm School
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className="selctedschool" style={{width:"440px",marginBottom:"20px"}}>
                        <Typography style={{
                          fontSize:"16px",
                          borderRadius:"24px",
                          width: "max-content",
                          padding:"12px 16px 12px 16px",
                          background:"#E8F6FE"
                        }}>
                           {this.state.selectedSchoolName}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </Box>
            </div>
            <div className="last-box" >
              <span style={{
                color:"#007DC6", 
                cursor: isBothComplete ? "not-allowed" : "pointer",
                opacity: isBothComplete ? 0.5 : 1
              }}>
                Skip School Connection
              </span>
              <div>
                <Button 
                  onClick={this.handleNavigation} 
                  disabled={!isBothComplete}
                  style={{
                    padding:"12px 24px 12px 24px",
                    borderRadius:"10px",
                    color: isBothComplete ? "#007DC6" : "#B2B2B2",
                    fontFamily:"gotham",
                    fontWeight:350,
                    background:"white",
                    cursor: isBothComplete ? "pointer" : "not-allowed",
                    opacity: isBothComplete ? 1 : 0.7
                  }}
                >
                  <div style={{display:"flex",gap:"20px",alignItems:"center"}}>
                    <div>Continue</div>
                    <div>
                      <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path 
                          d="M1 7H17M17 7L11 1M17 7L11 13" 
                          stroke={isBothComplete ? "#00649E" : "#B2B2B2"} 
                          strokeWidth="2" 
                          strokeLinecap="round" 
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}
// Customizable Area End
