import React from "react";
import { Grid, Button } from "@material-ui/core";
import { plus, right, rightAero } from "../assets";
import TeacherCard from "./TeacherCard";
import {
  getHeaderWelcomTitle,
  getStyleForDashBoard,
  getCredits,
  getUserDetails,
  getPendingRequestTxt,
  getCommaSeperator,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";

const LeadDasshBoard = ({ state, isFirstTime, navigation }: any) => {
  let user = getUserDetails();
  return (
    <Grid container className="lead-dashboard-container">
      <Grid container item spacing={5} className="common-header">
        <Grid sm={12} item className="title">
          <span>{getHeaderWelcomTitle()}</span>&nbsp;
          <span className="name"><span>{user.first_name.charAt(0).toUpperCase()}</span>{user.first_name.slice(1)}</span>
        </Grid>
      </Grid>
      <Grid
        sm={12}
        item
        container
        className="card0"
        style={getStyleForDashBoard()}
      >
        <Grid sm={6} item className="left">
          <span className="number">
            {getCommaSeperator(user.school.data.attributes.school_credits.data[0].attributes
              .current_balance)}
          </span>
          <span className="desc">
            School’s{" "}
            {getCredits(
              user.school.data.attributes.school_credits.data[0].attributes
                .current_balance
            )}
          </span>
        </Grid>
        <Grid sm={6} item className="right">
          <Button
            variant="contained"
            color="primary"
            className="btn-plus"
            onClick={() => navigation.navigate("AddCredits")}
          >
            <span className="btn-text">
              Add Credits &nbsp;&nbsp;
              <img src={plus} alt="plus" />
            </span>
          </Button>
        </Grid>
      </Grid>
      <Grid
        sm={12}
        item
        container
        className="card1 pointer"
        onClick={() => navigation.navigate("Requests")}
      >
        <Grid sm={6} item className="left">
          <span className="number">
            {state.mySchoolDetails.pending_requests_count}
          </span>
          <span className="desc">
            {getPendingRequestTxt(state.mySchoolDetails.pending_requests_count)}
          </span>
        </Grid>
        <Grid sm={6} item className="right">
          <img src={right} alt="icon" />
        </Grid>
      </Grid>
      <Grid sm={12} item container className="sub-header">
        <Grid sm={6} item>
          <span className="desc">My School</span>
        </Grid>
        <Grid sm={6} item className="right">
          <Button
            color="primary"
            className="btn-right-aero"
            endIcon={<img src={rightAero} alt="plus" />}
            onClick={() => navigation.navigate("MySchool")}
          >
            <span className="btn-text">Manage </span>
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="card-container">
        {state.teachersData.map((user: any, index: number) => {
          return (
            <Grid item sm={6} key={user.id}>
              <TeacherCard data={user} index={index} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LeadDasshBoard;
