import React from "react";
import { more } from "../assets";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { down, up } from "../assets";
import moment from "moment";
import { getDeliveryTitle } from "../../../../components/src/NativeWebRouteWrapper/Utils";

const TeacherHeader = ({ navigation, deliveryData }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigates = () => {
    navigation.navigate("Support");
  };
  return (
    <div className="header-card">
      <span className="header">{getDeliveryTitle(deliveryData.length)}</span>
      {deliveryData.map((itemData: any) => {
        return (
          <div className="card" key={itemData.id}>
            <List>
              <ListItem className="list-containerone">
                <ListItemText
                  primary={
                    <div style={{width:"95%"}}>
                      <span className="title">
                        <img
                          height={20}
                          width={20}
                          style={{ marginBottom: "3px" }}
                          src={itemData.attributes.classbox_emoji?.url}
                        ></img>
                        &nbsp;
                        {itemData.attributes.classbox_name}
                      </span>
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <div className="green">
                    <img
                      src={more}
                      alt="icon"
                      id="btn-click02"
                      onClick={handleClick}
                    />
                  </div>
                </ListItemSecondaryAction>

                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  <MenuItem id="menu-item" onClick={navigates}>
                    <span className="get-help">Get Help</span>
                  </MenuItem>
                </Menu>
              </ListItem>
              <Divider />
              <ListItem className="list-containertwo">
                {itemData.attributes.status === "shipped" && (
                  <ListItemText
                    secondary={
                      <span className="title-one">Estimated delivery</span>
                    }
                    primary={
                      <span className="title-zero">
                        {moment(itemData.attributes.shipped_from).format(
                          "MM/DD"
                        )}{" "}
                        -{" "}
                        {moment(itemData.attributes.shipped_to).format("MM/DD")}
                      </span>
                    }
                  />
                )}
                {itemData.attributes.status === "completed" && (
                  <ListItemText
                  primary={
                    <span className="title-zero">Order Confirmed</span>
                  }
                    secondary={
                      <span className="title-one">Classbox Will Ship Soon</span>
                    }
                  />
                )}
                <ListItemSecondaryAction>
                  <a href={itemData.attributes.tracking_url} target="_blank">
                    <Button variant="contained" className="btn-text00xy">
                      Track
                    </Button>
                  </a>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
        );
      })}
    </div>
  );
};

export default TeacherHeader;
