// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  InputLabel,
  Grid,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  loginSideImage,
  Logo,
  sendMailBack,
  forgetImage,
  closeIcon,
  errorIcon,
} from "./assets";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Snackbar from "@material-ui/core/Snackbar";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  createTheme,
} from "@material-ui/core/styles";
import LoginController, { Props } from "./LoginController";
import { FormHelperText } from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoaderComponent from "../../../components/src/NativeWebRouteWrapper/LoaderComponent";
import "../assets/index.scss";
import { GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';
const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is required."),
  password: Yup.string().required("Password is required"),
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const SchemaEmail = Yup.object().shape({
  forgotpasswordEmail: Yup.string()
    .email("Email must be in valid format!")
    .required("Email Address is required."),
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface ResponseJson {
  errors?: Array<{ [key: string]: string }>;
  account?: {
    data: {
      id: string;
      attributes: Record<string, any>;
    };
  };
  token?: string;
}

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container className="container-signup">
        <Grid item sm={12} md={6} xs={12} className="left">
          <Box>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                confirmPassword: "",
                ageCheckbox: "",
                termsCheckbox: "",
              }}
              //@ts-ignorek
              onSubmit={this.handleLogin}
              validationSchema={Schema}
              data-testId="handle-login"
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  //@ts-ignore
                  <Form className="form-container">
                    <Paper
                      style={{
                        width: "80%",
                      }}
                      className="form-container-sub"
                    >
                      <Grid item sm={12}>
                        <Typography
                          variant="h5"
                          gutterBottom
                          className="header-text title-medium"
                        >
                          Log In to Classbox
                        </Typography>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item sm={12}>
                          <InputLabel className="input-title title-medium">
                            User Email
                          </InputLabel>
                          <TextField
                            onChange={handleChange("email")}
                            data-test-id={"txtInputProductId"}
                            id="txtInputProductId"
                            value={values.email}
                            name="email"
                            placeholder="Enter Email Id"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            InputProps={{
                              endAdornment:
                                (!!errors.password && !!touched.password) ||
                                  this.state.message ===
                                  "Account not found, or not activated" ? (
                                  <img src={errorIcon} alt="icon" />
                                ) : (
                                  <></>
                                ),
                            }}
                            helperText={<ErrorMessage name="email" />}
                            error={
                              (!!errors.email && !!touched.email) ||
                              this.state.message ===
                              "Account not found, or not activated"
                            }
                          />
                          {this.state.message ===
                            "Account not found, or not activated" &&
                            this.state.showSnackbar === false ? (
                            <FormHelperText error className="helper-text">
                              {this.state.message}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid item sm={12}>
                          <InputLabel className="input-title title-medium">
                            Password
                          </InputLabel>
                          <TextField
                            onFocus={() => this.setState({ message: "" })}
                            onChange={handleChange("password")}
                            data-test-id={"txtInpuppassword"}
                            value={values.password}
                            name="password"
                            placeholder="Password"
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            type="password"
                            InputProps={{
                              endAdornment:
                                (!!errors.password && !!touched.password) ||
                                  this.state.message === "Incorrect password" ? (
                                  <img src={errorIcon} alt="icon" />
                                ) : (
                                  <></>
                                ),
                            }}
                            helperText={<ErrorMessage name="password" />}
                            error={
                              (!!errors.password && !!touched.password) ||
                              this.state.message === "Incorrect password"
                            }
                          />
                          {this.state.message === "Incorrect password" &&
                            this.state.showSnackbar === false ? (
                            <FormHelperText error className="helper-text">
                              {this.state.message}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid item sm={12}>
                        <Button
                            id="forgot-password-btn"
                            style={styled.forgotPass}
                            onClick={this.handleCloseForgetPassword}
                            className="login-button title-medium"
                          >
                            Forgot your password?
                          </Button>
                        </Grid>

                        <Grid container xs={12}>
                          <Grid item xs={6}>
                          <Button
                            size="small"
                            className="login-btn"
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={() => handleSubmit}
                            data-test-id={"btnAddExample"}
                            style={{ borderRadius: "10px" }}
                          >
                            Log In
                          </Button>
                          </Grid>
                          <Grid item xs={6}>
                          <Button
                            onClick={this.handleNavigation} 
                            className="request-invite-button title-medium"
                            data-test-id={"btnAddExample"}
                          >
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#007DC6",
                              }}
                            >
                              Signup For Classbox
                            </a>
                          </Button>
                          </Grid>
                          <Grid item xs={12} md={12} className="google-log-in-btn" style={{marginTop : "15px"}}>
                            <GoogleOAuthProvider clientId="270609563407-91r6a1g3nri7tq0igqnd7g3p36j4ksec.apps.googleusercontent.com">
                              <GoogleLogin
                                onSuccess={this.handleGoogleLogin}
                                onError={this.handleGoogleLogin}
                                data-test-id={"btnAddExample"}
                              />    
                            </GoogleOAuthProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <div className="logo-login">
            <img src={Logo} />
          </div>
        </Grid>
        <Grid sm={12} md={6} xs={12} className="right">
          <div className="image-container">
            <img src={loginSideImage} className="img" />
            <div className="image-text1">
              <p className="desc">"For the things we have to learn</p>
              <p className="desc">before we can do them, we learn by</p>
              <p className="desc">
                doing them." &nbsp;<span className="author">- Aristotle</span>
              </p>
            </div>
          </div>
        </Grid>
        <Dialog
          onClose={this.handleCloseForgetPassword}
          aria-labelledby="customized-dialog-title"
          open={this.state.forgotPasswordDialog}
        >
          <div className="bg-color">
            <img
              src={closeIcon}
              className="close-icon"
              onClick={this.handleCloseForgetPassword}
            ></img>
            <Box
              style={{
                height: "20%",
                backgroundColor: "#e8f7fe",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={forgetImage}></img>
              <Typography
                style={{
                  paddingTop: "10%",
                  paddingBottom: "10%",
                  alignSelf: "center",
                }}
              />
            </Box>
          </div>
          <Formik
            initialValues={{
              forgotpasswordEmail:
                //@ts-ignore
                document.getElementById("txtInputProductId")?.value || "",
            }}
            //@ts-ignorek
            onSubmit={this.forgotPasswordMail}
            validationSchema={SchemaEmail}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              touched,
              setFieldValue,
              isValid,
              dirty,
            }) => {
              return (
                //@ts-ignore
                <Form>
                  <DialogContent>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="modal-title-bold"
                    >
                      Forgot your password?
                    </Typography>
                    <Typography
                      style={{ fontSize: "0.875rem" }}
                      gutterBottom
                      className="modal-desc-light"
                    >
                      No worries! Enter your email down below and we will send a
                      password reset
                    </Typography>
                    <Typography
                      style={{ fontSize: "0.875rem" }}
                      gutterBottom
                      className="modal-desc-light"
                    >
                      link.
                    </Typography>
                    <InputLabel
                      className="input-label-bold-cmn"
                      style={{ marginTop: "5%" }}
                    >
                      User Email
                    </InputLabel>
                    <TextField
                      name="forgotpasswordEmail"
                      placeholder="mae@nasa.gov"
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      required
                      onChange={handleChange}
                      value={values.forgotpasswordEmail}
                      helperText={<ErrorMessage name="forgotpasswordEmail" />}
                      error={
                        !!errors.forgotpasswordEmail &&
                        !!touched.forgotpasswordEmail
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                      type="submit"
                      id="forgot-pass-btn"
                      className="send-button"
                      onSubmit={() => {
                        this.handleCloseForgetPassword();
                        handleSubmit();
                      }}
                      autoFocus
                    // color="primary"
                    >
                      <span className="confirmation-btn">Send</span>
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog
          className="dialogWidth"
          onClose={this.handleOpenMail}
          aria-labelledby="customized-dialog-title"
          open={this.state.openMailDialog}
        >
          <Box style={{ height: "20%", backgroundColor: "#e8f7fe", position: "relative", cursor: "pointer" }}>
            <img
              src={closeIcon}
              className="close-icon189144"
              onClick={this.handleOpenMail}
            ></img>
            <img src={sendMailBack} alt="back-image" className="back-image-tyrtryt55" />
          </Box>
          <DialogContent>
            <Typography variant="h5" gutterBottom className="modal-title-bold">
              We've just sent you a reset link!
            </Typography>
            <Typography
              style={{ fontSize: "0.875rem" }}
              gutterBottom
              className="modal-desc-light"
            >
              Open your email
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ margin: "3%", padding: '0px 30px 0px 30px' }}
              size="small"
              className="send-button"
              color="primary"
              type="submit"
              id="mail-btnn"
              variant="contained"
              onClick={this.handleOpenMail}
              data-test-id={"btnAddExample"}
            >
              <span className=" confirmation-btn">Close</span>
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.showSnackbar}
          autoHideDuration={5000}
          onClose={this.handleCloseSnackbar}
        >
          <MuiAlert
            onClose={this.handleCloseSnackbar}
            severity={this.state.severity}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        <LoaderComponent open={this.state.loading} />
      </Grid>
    );
  }
}

const styled = {
  forgotPass: {
    float: "right" as "right",
    width: "auto",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    fontSize: 13,
    color:"#007DC6"
  }
};

// Customizable Area End
