// Customizable Area Start
import React from "react";
import {Link} from "react-router-dom";
import { Grid, Divider, Button ,DialogTitle,Dialog,Typography,Box,DialogContent} from "@material-ui/core";
import { styled  } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import {
  Present,
  Student,
  Teacher,
  arrowRight,
  award,
  cardOneback,
  download,
  durationIcon,
  files,
  gradeIcon,
  rightWhite,
  rocket,
  shareM,
  subject,
  tworingsIcon,
  copy,
  schoology,
  canvasLms,
  googleClassroom,
  blackboard,
  vector,
  fileNon,
  share
} from "../assets";
import { creatorCard2 } from "../../../ProjectTemplates/src/assets";
import {
  addS,
  getCommaSeperator,
  getGradeCombo,
  getMinutes,
  getTrimTitle,
  getTwitterURL,
  oneToMany,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";
import StandardsAccordian from "./StandardsAccordian";
const ProjectOverview = ({
  navigation,
  addProjectPlan,
  state,
  setTab,
  data,
  handleStartTech,
  addLmsCard,
  toggleOverview,
  copyToClipboard,
  resetCopiedState,
  classboxsPartsData,
}: any) => {
  let {
    classbox_parts,
    emoji,
  } = classboxsPartsData;
  let {
    title,
    grade,
    subject_tags,
    credit_award,
    duration,
    short_description,
    hero_image,
    present_url,
    student_view,
    related_training,
    related_classboxes,
    standard_sets,
    related_requirements,
  } = data;
  // Customizable Area End

  // Customizable Area Start

  interface ClassboxPart {
    id: string;
    type: string;
    attributes: {
      name: string;
      checkout_part: boolean;
      purchase_part: boolean;
      supplier: string;
      quantity: number;
      unit: string;
      credits_cost: number;
      tooltip: string;
    };
  }
  
  interface JsonResponse {
    parts: ClassboxPart[];
  }
  interface Requirement {
    attributes: {
      id: string;
      name: string;
      description: string;
      tag: string;
      category: string;
      icon: string;
    }
  }
  
  interface RequirementsResponse {
    category: string;
    requirements: {
      data: Requirement[];
    };
  }
  const heroImage = () => {
    return <>
    <div className="header-one">
        <img src={hero_image?.url} alt="img" className="back-image" />
        <div className="cover-div"></div>
        <h1 className="title">{getTrimTitle(40, title)}</h1>
        <div className="componet-abs">
          {state.previewMode === "default" 
          ?<Button
            id="handleStartTech"
            className="start-tech-btn pg-sk1"
            onClick={()=> handleStartTech()}
          >
            Start teaching
            &nbsp;
            <img src={rightWhite} alt="icon" />
          </Button>
          :<Button
            id="handleStartTech"
            className="start-tech-btn pg-sk1"
            onClick={()=> toggleOverview()}
          >
            Preview Steps{" "}
            &nbsp;
            <img src={rightWhite} alt="icon" />
          </Button>}
         
           { modalStartTeach()}
          
          <div className="btn-container project-overview-button">
            {(!related_classboxes?.title || (related_classboxes && related_classboxes.owned_classbox)) && (
              <>
                <Button
                  className="cmn-btn"
                  id="add-to-plan"
                  onClick={addProjectPlan}
                >
                  <img src={rocket} alt="icon" />
                  &nbsp;<span className="project-card-text">Add To Plan</span>
                </Button>
                <Button
                    className="cmn-btn"
                    id="add-to-lms"
                    onClick={() => addLmsCard()}
                  >
                    <img src={vector} alt="icon" />
                    &nbsp;<span className="project-card-text">Add To LMS </span>
                  </Button>
                <a
                  href={`mailto:feedback@classbox.com`}
                  target="_blank"
                  className="cmn-btn"
                >
                  <img src={files} alt="email" />
                  &nbsp;<span className="project-card-text">Feedback</span>
                </a>
                <a
                  href={data?.pdf_file?.url}
                  target="_blank"
                  className="cmn-btn"
                >
                  <Button className="cmn-btn" style={{ marginRight: "0px" }}>
                    <img src={download} alt="icon" />
                    &nbsp;<span className="project-card-text">Download</span>
                  </Button>
                </a>
              </>
            )}
            {related_classboxes && related_classboxes.owned_classbox === false && (
              <Button
              id="cmn-btn1"
                className="cmn-btn1 pg-sk2"
                onClick={() =>
                  navigation.navigate("PurchaseClassBox", {
                    ids: related_classboxes.id,
                  })
                }
              >
                
                <img src={tworingsIcon} alt="icon" />
                &nbsp;&nbsp;Order Classbox
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  }

  const modalLmsCard = () => {
    const urlLms = data?.student_view;
    const modifiedUrl = urlLms?.replace('/ProjectDetailsSub', '');
    
    return <>
      {state.modalStateLms === true && <>
        <TimesTakenModal id='modalStateLms' onClose={() => addLmsCard()} aria-labelledby="add-to-lms-dialog-title" open={state.modalStateLms}>
          <DialogTitle id="add-to-lms-dialog-title">
            <Box className="closeBtnBox">
              <CloseIcon id="CloseIcon" onClick={() => addLmsCard()} />
            </Box>
            <Typography className="titleText" >Add to LMS</Typography>
          </DialogTitle>
          <DialogContent className="dialogContLms">
            <Box className="">
              <Box className="">
                <Typography style={{ fontWeight: 500, color: '#344054', marginBottom: "10px", fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace' }}>Add manually</Typography>
              </Box>
              <Box className="dialogCopyBox">
                <a href={data?.student_view} target="_blank"  style={{ marginTop: "10px", flexGrow: 1, color: '#344054', fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace', wordBreak: "break-all" }} id="public-url">
                  {modifiedUrl}
                </a>
                <Box>
                  <Button
                    className="cmn-btn tooltip"
                    id="click-to-copy"
                    onClick={() => copyToClipboard()}
                    onMouseOver={() => resetCopiedState()}
                    style={{ minWidth: "52px" }}
                  >
                    <span className="tooltiptext">{state.copied ? "Copied" : "Click to copy"}</span>
                    <img src={copy} className="copyImg" />
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography style={{ fontWeight: 350, color: '#344054', fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace' }}>Import content (Coming Soon)</Typography>
              <Box className="dialogContLmsBox" >
                <Box className="canvasLmsBtnBox container">
                  <button className="imageButton"><img src={canvasLms} className="image " /></button>
                  <div className="text">Coming soon</div>
                </Box>
                <Box className="blackboardBtnBox container" >
                  <button className="imageButton"><img src={blackboard} className="image" /></button>
                  <div className="text">Coming soon</div>
                </Box>
              </Box>
              <Box className="dialogContLmsBox" >
                <Box className="googleClassroomBtnBox container">
                  <button className="imageButton"><img src={googleClassroom} className="image" /></button>
                  <div className="text">Coming soon</div>
                </Box>
                <Box className="schoologyBtnBox container" >
                  <button className="imageButton"><img src={schoology} className="image" /></button>
                  <div className="text">Coming soon</div>
                </Box>
              </Box>
            </Box>

          </DialogContent>
          <Box className="dialogContNeedHelp">
            <Typography align="center" style={{ fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace' }}>
              <Link to="/Support" id="support-url">Need some help?</Link>
            </Typography>
          </Box>
        </TimesTakenModal>
      </>}
    </>
  }
  const classParts = () => {
    const classboxParts = classbox_parts || {}
    const classPartsData = classboxParts?.data || []
    const requireArray = related_requirements || []
    return <>
      {classPartsData[0]?.type === "classbox_parts"
        &&
        <Box style={{ marginTop: "10px" }}>
          <Typography className="title_m1">
            Classbox Parts
          </Typography>
          <Box
            className="m1_BoxWrap"
          >
            {classPartsData.map((value: ClassboxPart) => (<Box
              className="m1_card"
            >
              <Box className="imagBox">
                <img className="imagwarap"
                  src={emoji?.url} />
              </Box>
              <Box
                className="contantBox">
                <Typography
                  className="title_card">
                  {value.attributes.name}
                </Typography>
                <Typography className="title_sub_card">{value.attributes.tooltip}</Typography>
              </Box>
            </Box>))}
          </Box>
        </Box>
      }
      {requireArray.map((value: RequirementsResponse) =>
      (value.category === "IT" &&
        <Box style={{ marginTop: "15px" }}>
          <Typography
            className="title_m1"
          >
            IT Requirements
          </Typography>
          {value.requirements.data.map((value: Requirement) => (<Box
            className="m1_BoxWrap"
          >
            <Box
              className="m1_card"
            >
              <Box
                className="imagBox">

                <img
                  className="imagwarap"
                  src={value.attributes.icon}
                />
              </Box>
              <Box className="contantBox">
                <Typography
                  className="title_card">{value.attributes.name}</Typography>
                {value.attributes.tag === "Required" &&
                  <Button style={{ backgroundColor: "#FEE4E2", width: "90px", borderRadius: "50px", color: "#F97066", fontSize: "10px", cursor: "default" }}>Required</Button>}
                {value.attributes.tag === "Recommended" &&
                  <Button style={{ backgroundColor: "#FEEDE6", width: "120px", borderRadius: "50px", color: "#DC6803", fontSize: "10px", cursor: "default" }}>Recommended</Button>}
                {value.attributes.tag === "Optional" &&
                  <Button style={{ backgroundColor: "rgb(245, 247, 249)", width: "90px", borderRadius: "50px", color: "#475467", fontSize: "10px", cursor: "default" }}>Optional</Button>}
                <Typography className="title_sub_card">
                  {value.attributes.description}
                </Typography>
              </Box>
            </Box>
          </Box>))}
        </Box>))}
    </>
  }
  const requireMents = () => {
    const classboxParts = classbox_parts || {}
    const classPartsData = classboxParts?.data || []
    const requireArray = related_requirements || []
    return <>
      {requireArray[0]?.category || classPartsData[0]?.type === "classbox_parts" ? <>

        <RequirementsTab>
          {classParts()}

          {requireArray.map((value: RequirementsResponse) => (value.category ===
            "Other_Materials" &&
            <Box style={{ marginTop: "15px" }}>
              <Typography className="title_m1">
                Other Materials
              </Typography>
              {value.requirements.data.map((value: Requirement) => (<Box className="m1_BoxWrap">

                <Box className="m1_card">

                  <Box className="imagBox">

                    <img className="imagwarap" src={value.attributes.icon} />
                  </Box>
                  <Box className="contantBox">
                    <Typography className="title_card">{value.attributes.name}</Typography>
                    {value.attributes.tag === "Required"
                      && <Button style={{ backgroundColor: "#FEE4E2", width: "90px", borderRadius: "50px", color: "#F97066", fontSize: "10px", cursor: "default" }}>Required</Button>}
                    {value.attributes.tag === "Recommended"
                      && <Button style={{ backgroundColor: "#FEEDE6", width: "120px", borderRadius: "50px", color: "#DC6803", fontSize: "10px", cursor: "default" }}>Recommended</Button>}
                    {value.attributes.tag === "Optional"
                      && <Button style={{ backgroundColor: "rgb(245, 247, 249)", width: "90px", borderRadius: "50px", color: "#475467", fontSize: "10px", cursor: "default" }}>Optional</Button>}
                    <Typography className="title_sub_card">{value.attributes.description}
                    </Typography>

                  </Box>
                </Box>
              </Box>))}
            </Box>))}
          {requireArray.map((value: RequirementsResponse) => (value.category === "Space"
            &&
            <Box style={{ marginTop: "10px" }}>
              <Typography className="title_m1">
                Space Requirements
              </Typography>
              <Box
                className="m1_BoxWrap"
              >
                {value.requirements.data.map((value: Requirement) => (<Box
                  className="m1_card"
                >
                  <Box className="imagBox">
                    <img className="imagwarap"
                      src={value.attributes.icon} />
                  </Box>
                  <Box
                    className="contantBox">
                    <Typography
                      className="title_card">
                      {value.attributes.name}
                    </Typography>
                    {value.attributes.tag === "Required" &&
                      <Button className="btnRequired" >Required</Button>}
                    {value.attributes.tag === "Recommended" &&
                      <Button className="btnRecommended" >
                        Recommended</Button>}
                    {value.attributes.tag === "Optional" &&
                      <Button className="btnOptional" >Optional</Button>}
                    <Typography className="title_sub_card">{value.attributes.description}</Typography>
                  </Box>
                </Box>))}
              </Box>
            </Box>
          ))}
        </RequirementsTab></>
        : <RequirementsTab>
          <Box className="nonDataBox">
            <img src={fileNon} />
            <Typography className="nonDataTitle">No Requirements!</Typography>
            <Typography className="nonDataSub">This project does not require any equipment or <br /> special requirements.</Typography>
          </Box>
        </RequirementsTab>
      }
    </>
  }
  const modalStartTeach = () => {
    return <>
      {state.modalStaetTech === true && <>
        <TimesTakenModal id='modalClose' onClose={() => handleStartTech()} aria-labelledby="simple-dialog-title" open={state.modalStaetTech}>
          <DialogTitle id="simple-dialog-title">
            <Box className="titleBox">
              <Typography className="titleText" >Start Project</Typography>
              <Box className="closeBtnBox">
                <CloseIcon id="CloseIcon" onClick={() => handleStartTech()} />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent className="dialogCont">
            <a href={present_url} target="_blank" rel="noopener noreferrer" className="dialogContBox" >
              <Box className="dialogSubBox" >
                <img src={Present} className="iconBox" />
                <Typography style={{ fontWeight: 700, color: '#344054' }}>Present</Typography>
              </Box>
              <Box className="shareBtnBox" >
                <a href={present_url} target="_blank" rel="noopener noreferrer">
                  <img src={shareM} className="shareImg" />
                </a>
              </Box>
            </a>
            <a href={student_view} target="_blank" rel="noopener noreferrer" className="dialogContBox">
              <Box className="dialogSubBox">
                <img src={Student} className="iconBox" />
                <Typography style={{ fontWeight: 700, color: '#344054' }}>Student view</Typography>
              </Box>
              <Box className="shareBtnBox" >
                <a href={student_view} target="_blank" rel="noopener noreferrer">
                  <img src={shareM} className="shareImg" />
                </a>
              </Box>
            </a>
            <Box onClick={()=> toggleOverview()} id='toggleOverview' className="dialogContBox">
              <Box className="dialogSubBox">
                <img src={Teacher} className="iconBox" />
                <Typography style={{ fontWeight: 700, color: '#344054' }}>Teacher view</Typography>
              </Box>
              <Box className="shareBtnBox"><img src={arrowRight} className="shareImgArrow" /></Box>
            </Box>
          </DialogContent>
        </TimesTakenModal>
      </>}
    </>
  }
  const projectDetails = () => {

    return <>
      <div className="content">
        <div className="left">
          <div className="title1">Project Details</div>
          <div className="content-card">
            <div className="tabs-header">
              <div
                id="tab0btn"
                className={
                  state.tab === 0
                    ? "tab-title-container pointer"
                    : "tab-title-container-default pointer"
                }
                onClick={() => setTab(0)}
              >
                <span className="txt-title">&nbsp;Overview</span>
              </div>
              <div
                id="tab1btn"
                className={
                  state.tab === 1
                    ? "tab-title-container pointer"
                    : "tab-title-container-default pointer"
                }
                onClick={() => setTab(1)}
              >
                <span className="txt-title">&nbsp;Standards</span>
              </div>
              <div
                id="tab2btn"
                className={
                  state.tab === 2
                    ? "tab-title-container pointer"
                    : "tab-title-container-default pointer"
                }
                onClick={() => setTab(2)}
              >
                <span className="txt-title">&nbsp;Requirements</span>
              </div>
            </div>
            <div className="tabs-content">
              {state.tab === 0 && (
                <>
                  <h1 className="tab-con-title">Description</h1>
                  <span className="tab-con-des">{short_description}</span>
                  <Grid container className="card-container" spacing={1}>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={gradeIcon} alt="img" />
                        <span className="txt-title">Grades</span>
                        <span className="txt-des">
                          {getGradeCombo(grade)}
                        </span>
                      </div>
                    </Grid>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={durationIcon} alt="img" />
                        <span className="txt-title">Duration</span>
                        <span className="txt-des">{duration} {getMinutes(duration)}</span>
                      </div>
                    </Grid>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={subject} alt="img" />
                        <span className="txt-title">Subject{oneToMany(subject_tags)}</span>
                        <span className="txt-des">
                          {subject_tags.join(", ")}
                        </span>
                      </div>
                    </Grid>
                    <Grid item sm={6} className="card-grid">
                      <div className="card">
                        <img src={award} alt="img" />
                        <span className="txt-title">Credit Award{addS(credit_award)}</span>
                        <span className="txt-des">
                          {getCommaSeperator(credit_award)}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
              {state.tab === 2 && (
                <>
                  {requireMents()}
                </>
              )}
              {state.tab === 1 &&
                <StandardsAccordian standard_sets={standard_sets} />
              }
            </div>

          </div>
        </div>
        <Divider flexItem className="divider-grey" orientation="vertical" />
        <div className="right">

          <div className="title1">
            Resources
          </div>
          {related_classboxes?.title &&
            (
              <div className="card" >
                <img className="card-back-image" alt="img" src={cardOneback} />
                <div className="card-content">

                  <div className="card-details" >
                    <h1 className="title-card" >
                      Classbox:
                    </h1>
                    <span className="desc-card" >
                      {related_classboxes?.title}
                    </span>
                  </div>
                  <img
                    src={related_classboxes?.emoji_url.url}
                    className="icon-card-one"
                    style={{ width: "30%" }}
                    alt="img"
                  />
                </div>
              </div>
            )}
          {related_training?.length > 0 && (
            <div className="card0">
              <h1 className="title-card">Related Training{oneToMany(related_training)}:</h1>
              {related_training.map((item: any) => (
                <div key={item.id}>
                  <span className="desc-card">{item.title}</span>
                  <img
                    src={item?.hero_image_url}
                    className="image-card0 "
                    alt="img"
                  />
                </div>
              ))}
            </div>
          )}

          <Grid item className="card pointer" sm={12}>
            <img src={creatorCard2} alt="icon" className="card-back-image" />
            <div className="card-content">
              <div className="card-details">
                <span className="title-card">Project Creator: </span>
                <span className="desc-card">{data.admin_data.data.attributes.first_name}{" "}{data.admin_data.data.attributes.last_name}</span>
              </div>
              <img
                src={
                  data.creator_card?.url
                }
                className="icon-card-one"
                alt="img"
                style={{ width: "30%" }}
              />
            </div>
          </Grid>
        </div>
      </div>
    </>
  }

  // Customizable Area End

  // Customizable Area Start
  return (
    <>
    <Grid item sm={9} container className="main1">
        {heroImage()}
        {modalLmsCard()}
        {projectDetails()}
    </Grid>
    </>
  );
  
};
const RequirementsTab = styled(Box)({
  width: "100%",
  "& .btnRequired": { backgroundColor: "#FEE4E2", width: "90px", borderRadius: "50px", color: "#F97066", fontSize: "10px", cursor: "default" },
  "& .btnRecommended": { backgroundColor: "#FEEDE6", width: "120px", borderRadius: "50px", color: "#DC6803", fontSize: "10px", cursor: "default" },
  "& .btnOptional": { backgroundColor: "rgb(245, 247, 249)", width: "90px", borderRadius: "50px", color: "#475467", fontSize: "10px", cursor: "default" },
  "& .title_m1": {
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace',
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#242a32",
  },
  "& .m1_BoxWrap": {
    marginTop: "10px",
    padding: "5px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .m1_card": {
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    boxShadow: "0px 1px 7px -5px rgba(242, 244, 247, 1)",
    borderRadius: "10px",
    border: "2px solid rgba(242, 244, 247, 1)",
    display: "flex",
    gap: "20px",
    padding: "10px 15px",
  },
  "& .imagBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .imagwarap": {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
  },
  "& .contantBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
  },
  "& .title_card": {
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace',
    fontStyle: "normal !important",
    fontWeight: 900,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: "rgba(52, 64, 84, 1) !important",
  },
  "& .title_sub_card": {
    fontFamily: '"Gotham-Light", "Courier New", Courier, monospace !important',
    fontStyle: "normal !important",
    fontWeight: 900,
    fontSize: "14px !important",
    lineHeight: "20px !important",
    color: "rgba(71, 84, 103, 1) !important",
  },
  "& .nonDataBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 20px",
    gap: "10px",
  },
  "& .nonDataTitle": {
    fontSize: "24px !important",
    lineHeight: "32px !important",
    textAlign: "center",
    color: "#242A32 !important",
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace',
    fontStyle: "normal !important",
    fontWeight: 900,
  },
  "& .nonDataSub": {
    fontSize: "16px !important",
    lineHeight: "24px !important",
    textAlign: "center",
    color: "#475467 !important",
    fontFamily: '"Gotham-Light", "Courier New", Courier, monospace !important',
    fontStyle: "normal !important",
    fontWeight: 600,
  },

})
const TimesTakenModal = styled(Dialog)({

  "& .titleText": {
    fontSize: "25px",
    fontWeight: "bold",
    fontFamily: "Gotham"
  },
  "& .closeBtnBox": {
    cursor: "pointer",
    width: "32px",
    height: "32px",
    display: "flex",
    color: "grey",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 11%) 0px 2px 7px -1px",
    borderRadius: "50%",
    marginLeft: 'auto',
    padding: "6px"
  },

  "& .titleBox": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column-reverse",
    marginTop: "15px"
  },
  "& .dialogCont": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px"
  },

  "& .dialogContLms": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "0 24px 24px 24px"
  },
  "& .dialogContBox": {
    display: "flex",
    backgroundColor: "#E8EEF494",
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #EAECF0",
    cursor: "pointer",
  },
  "& .dialogSubBox": {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    width: "50%",
  },
  "& .iconBox": {
    height: "28px",
    width: "25px",
  },
  "& .shareBtnBox": {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    width: "100%"
  },
  "& .shareImg": {
    height: "15px",
    width: "15px",
    cursor: "pointer"
  },
  "& .shareImgArrow": {
    height: "12px",
    width: "8px",
    cursor: "pointer"
  },
  "& .dialogContLmsBox": {
    display: "flex",
    borderRadius: "10px",
    gap: "8px",
    flexWrap: "wrap",
    marginTop: "0.5rem"
  },
  "& .dialogCopyBox": {
    display: "flex",
    borderRadius: "10px",
    border: "1px solid #EAECF0",
    padding: "0 5px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAFB"
  },
  "& .copyImg": {
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #EAECF0",
    backgroundColor: "#FFFFFF"
  },
  "& .dialogContNeedHelp": {
    padding: "16px",
    borderTop: "1px solid #EAECF0",
  },
  "& .text": {
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    margin: "0 auto",
    textAlign: 'center',
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.86)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    fontFamily: '"Gotham-Medium1", "Courier New", Courier, monospace',
  },
  "& .container:hover": {
    opacity: 0.5,
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    "& .text": {
      opacity: 1,
    }
  },
  "& .container": {
    position: "relative",
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    flexGrow: 1
  },
  "& .image": {
    height: "50px",
    width: "175px",
    objectFit: "contain"
  },
  "& .tooltiptext": {
    visibility: "hidden",
    width: "120px",
    backgroundColor: "rgba(106, 103, 103, 0.86)",
    color: "#fff",
    textAlign: "center",
    borderRadius: "6px",
    padding: "5px 0",
    position: "absolute",
    zIndex: 1,
    top: "100%",
    left: "50%",
    marginLeft: "-60px",
  },
  "& .tooltiptext::after": {
    content: "",
    position: "absolute",
    bottom: "100%",
    left: "50%",
    marginLeft: "-5px",
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: "transparent transparent black transparent",
  },
  "& .tooltip:hover .tooltiptext": {
    visibility: "visible",
  },
  "& .imageButton": {
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    padding: "14px",
    backgroundColor: "#FFFFFF",
    width: "100%"
  },
  "& .tooltip": {
    padding: 0,
    borderRadius: "10px",
    margin: "4px",
  },
})
export default ProjectOverview;
// Customizable Area End
