import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography } from "@material-ui/core";

interface FileUploadProps {
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  acceptedFileTypes?: string;
  title: string;
  subtitle: string;
  fileIcon: string;
  uploadIcon: string;
  downloadIcon: string;
  currentFile?: string;
  errorMessage?: string;
}

const FileDragDrop: React.FC<FileUploadProps> = ({
  onFileSelect,
  acceptedFileTypes = 'application/pdf',
  title,
  subtitle,
  fileIcon,
  uploadIcon,
  downloadIcon,
  currentFile,
  errorMessage
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const div = dropRef.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);
    }

    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    };
  }, []);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (validateFile(file)) {
        handleFile(file);
      }
    }
  };

  const validateFile = (file: File): boolean => {
    if (!acceptedFileTypes) return true;
    const acceptedTypes = acceptedFileTypes.split(',').map(type => type.trim());
    return acceptedTypes.includes(file.type);
  };

  const handleFile = (file: File) => {
    const syntheticEvent = {
      target: {
        files: [file]
      }
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    
    onFileSelect(syntheticEvent);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (validateFile(e.target.files[0])) {
        handleFile(e.target.files[0]);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      <Grid item>
        <div
          ref={dropRef}
          className={`pdf-container ${isDragging ? 'dragging' : ''}`}
          style={{
            border: isDragging ? '2px dashed #0000ff' : '2px dashed #ccc',
            borderRadius: '4px',
            padding: '20px',
            cursor: 'pointer',
            backgroundColor: isDragging ? 'rgba(0, 0, 255, 0.05)' : 'transparent',
            transition: 'all 0.2s ease'
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={1} className="round-background">
              <img src={uploadIcon} alt="Upload" />
            </Grid>
            
            <Grid item sm={8} xs={12} className="name-container">
              <Typography variant="subtitle1">
                Select a file or drag and drop here
              </Typography>
              <Typography variant="body2">{subtitle}</Typography>
            </Grid>
            
            <Grid item sm={3} xs={12}>
              <div style={{ position: 'relative' }}>
                <button 
                  className="select-file-button"
                  onClick={handleButtonClick}
                  type="button"
                >
                  Select File
                </button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept={acceptedFileTypes}
                  onChange={handleFileInputChange}
                  data-test-id="sole-source-input"
                  style={{ display: 'none' }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>

      {errorMessage && (
        <Grid item>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
      )}

      {currentFile && (
        <Grid item>
          <Grid container className="pdf-box" alignItems="center">
            <Grid item sm={1} xs={2}>
              <img src={fileIcon} alt="File" />
            </Grid>
            <Grid item sm={10} xs={8}>
              <Typography>{currentFile.split("/").pop()}</Typography>
            </Grid>
            <Grid item sm={1} xs={2}>
              <Typography
                component="a"
                href={currentFile}
                style={{ border: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={downloadIcon} alt="Download" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FileDragDrop;