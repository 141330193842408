import React, { useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { withRouter } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  handleLogout,
  getUserDetails,
  showHeader,
  isTeacher,
  changeLeadMode,
  isLead,
  getLeadMode,
  canDrawerOpen,
  getTeacherName,
  getCommaSeperator,
  getCredits,
  getTrimTitle,
  getSchoolCurrBaklance,
  isVendor
} from "./Utils";
//@ts-ignore
import AppLogo from "../AppLogo.svg";
//@ts-ignore
import CloseDrawerLogo from "../CloseDrawerLogo.svg";
//@ts-ignore
import LeftDoubleLogo from "../LeftDouble.svg";
//@ts-ignore
import RightDrawerLogo from "../RightDouble.svg";
//@ts-ignore
import DashboardIcon from "../DashboardIcon.svg";
//@ts-ignore
import DashboardIconActive from "../DashboardIconActive.svg";
//@ts-ignore
import RequestsIcon from "../RequestsIcon.svg";
//@ts-ignore
import RequestsIconActive from "../RequestsIconActive.svg";
//@ts-ignore
import SchoolIcon from "../SchoolIcon.svg";
//@ts-ignore
import SchoolIconActive from "../SchoolIconActive.svg";
//@ts-ignore
import Staff from "../Staff.svg";
//@ts-ignore
import StaffActive from "../StaffActive.svg";
//@ts-ignore
import SupportIcon from "../SupportIcon.svg";
//@ts-ignore
import SupportIconActive from "../SupportIconActive.svg";
//@ts-ignore
import ClassBoxesIcon from "../ClassBoxesIcon.svg";
//@ts-ignore
import ClassBoxesIconActive from "../ClassBoxesIconActive.svg";
//@ts-ignore
import LockersIcon from "../LockersIcon.svg";
//@ts-ignore
import LockersIconActive from "../LockersIconActive.svg";
//@ts-ignore
import TrainingIcon from "../TrainingIcon.svg";
//@ts-ignore
import TrainingIconActive from "../TrainingIconActive.svg";
//@ts-ignore
import ProjectPlanIcon from "../ProjectPlan.svg";
//@ts-ignore
import ProjectPlanIconActive from "../ProjectPlanActive.svg";
//@ts-ignore
import PlusIcon from "../PlusIcon.svg";
//@ts-ignore
import rightAero from "../SideAero.svg";
//@ts-ignore
import UpAero from "../UpAerow.svg";
//@ts-ignore
import Vector from  "../Vector.svg";
//@ts-ignore
import Home from  "../Home.svg";
//@ts-ignore
import Order from  "../Orders.svg";
//@ts-ignore
import VectorActive from  "../VectorActive.svg";
//@ts-ignore
import HomeActive from  "../HomeActive.svg";
//@ts-ignore
import OrderActive from  "../OrdersActive.svg";
//@ts-ignore
import activeSchool from "../activeSchool.svg";
//@ts-ignore
import inactiveSchool from "../school.svg";


import "../Drawer.scss";
import SwitchLoader from "./SwitchLoader";
import {useSelector} from "react-redux";
import RightIcon from '@material-ui/icons/ChevronRight';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Tooltip } from "@material-ui/core";

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap", 
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      padding: "24px 20px",
      height: "93vh",
      boxSizing: "unset",
    },
    vendorDrawer:{
      backgroundColor: "#F2F4F7",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
      backgroundColor: "#FFFFFF" ,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      ...theme.mixins.toolbar,
    },
    toolbarVendor:{
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginLeft:"15px",
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: "0px 20px",
      backgroundColor: "#FCFCFD",
    },
    content1: {
      flexGrow: 1,
      paddingRight: "20px",
      backgroundColor: "#FCFCFD",
      boxSizing: "unset"
    },
    routes: {
      height: "74%",
      overflowY: "auto",
      overflowX: "hidden",
    },
  })
);
function DrawerComponent({ children, history, pathName }: any) {
  const pendingRequestCount = useSelector((state: any) => state.pendingRequestSlice.value);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [canWeDrawerOpen, setCanDrawerOpen] = React.useState(canDrawerOpen());
  const [openLogOut, setopenLogOut] = React.useState(false);
  const [user, setUser] = React.useState({
    first_name: "",
    last_name: "",
    lead_type: "",
    teacher_type: "",
    teacher_mode: false,
    role: {
      name: "",
    },
    image: { url: "" },
    account_credits: {
      data: [{
        attributes: {
          recieved: 0,
          award: 0,
          reward: 0,
          claimed: 0,
          reclaimed: 0,
          spent: 0,
          current_balance: 0,
          unused_earned_credits :0,
        }
      }]
    },
    school: {
      data: {
        id: "",
        type: "",
        attributes: {
          name: "",
          address: "",
          current_balance: 0,
          spent: 0,
          school_credits: {
            data: [
              {
                attributes: {
                  recieved: 0,
                  award: 0,
                  reward: 0,
                  claimed: 0,
                  reclaimed: 0,
                  spent: 0,
                  current_balance: 0,
                }
              }
            ]
          },
          manager_details: {
            email: "",
            first_name: "",
            last_name: ""
          }
        }
      }
    },
    vendor_company: {
      data: {
        attributes: {
          vendor_logo: {
            url: ""
          },
        }
      }
    }
  });
  const style = openLogOut
  ? {
      border: "1px solid #F2F4F7",
      borderRadius: "10px",
      marginBottom: "16px",
      boxShadow:
        "0px 0.93px 2.75px 0px #080C3A03, 0px 2.35px 6.95px 0px #080C3A05, 0px 4.78px 14.18px 0px #080C3A06, 0px 9.86px 29.2px 0px #080C3A07, 0px 27px 80px 0px #080C3A0A"
    }
  : {
      borderRadius: "5px",
      marginBottom: "16px",
      boxShadow: "none"
    };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const divRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setopenLogOut(false); 
    }
  };
  const [loader, setLoader] = React.useState({ open: false, mode: "teacher" })
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getClassName = (pathname: string,vendor?:boolean) => {
    return history.location.pathname.substring(1).toLowerCase() == pathname
      ? (vendor?"active-vendor":"active")
      : "non-active";
  };
 
  const getClassNameForBack = (pathname: string) => {
    return history.location.pathname.substring(1).toLowerCase() == pathname
      ?  "drawer-link-txt-1"
        :"drawer-link-txt";
  };
  const getClassNameVendor = (pathname: string) => {
    return history.location.pathname.substring(1).toLowerCase() == pathname
        ?"drawer-link-txt-1-vendor"
        :"drawer-link-txt-vendor";
  };
  const handleClickVendor = () => {
    setopenLogOut(prevIsOpen => !prevIsOpen)
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const userDetails = useMemo(() => {
    return localStorage.getItem("userInfo");
  }, []);

  const openx = Boolean(anchorEl);
  const id = openx ? "simple-popover" : undefined;

  useEffect(() => {
    const handleStorage = () => {
      setUser(getUserDetails());
    };
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); 
  useEffect(() => {
    const handleStorage = () => {
      setUser(getUserDetails());
    };
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [userDetails]);

  useEffect(() => {
    setUser(getUserDetails());
    if (isVendor()) {
      document.querySelector('.MuiDrawer-paperAnchorDockedLeft')?.classList.add('no-border');
    } else {
      document.querySelector('.MuiDrawer-paperAnchorDockedLeft')?.classList.remove('no-border');
    }   
  }, []);
  React.useEffect(() => {
    let listen = history.listen((location: any, action: any) => {
      if (localStorage.getItem("prevStateOfDrawer") && !canDrawerOpen()) {
        setOpen(true);
        // setOpen(localStorage.getItem("prevStateOfDrawer") !== "false");
        setCanDrawerOpen(canDrawerOpen());
        // localStorage.removeItem("prevStateOfDrawer");
        return;
      }
      if (canDrawerOpen()) {
        localStorage.setItem("prevStateOfDrawer", `${open}`);
      }
      setOpen(() => !canDrawerOpen() ? open : false);
      setCanDrawerOpen(canDrawerOpen());
    });
    return () => {
      listen()
    };
  }, [open]);

  React.useEffect(() => {
    if (canDrawerOpen()) {
      setOpen(() => !canDrawerOpen() ? open : false);
      setCanDrawerOpen(canDrawerOpen());
    }
      }, []);
     
      let leadOrTeacherCredit = (isTeacher() && !isVendor())
      ? user.school==null? (user?.account_credits?.data[0]?.attributes?.unused_earned_credits||0):(user.account_credits?.data?.[0]?.attributes?.current_balance || 0)
      : (!isVendor())
        ? (user.school?.data?.attributes?.school_credits?.data?.[0]?.attributes?.current_balance || 0)
        : 0;

  return (
    <>
      <div className="drawer-main">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.vendorDrawer]: isVendor(),
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,    
          }      
         )}
          classes={{
            paper: clsx({
              [classes.vendorDrawer]: isVendor(),
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {!canWeDrawerOpen && !open && (
            <div onClick={handleDrawerOpen} className="icon-close-open">
              {!open ? (
                <div className="right-open-icon">
                  <img src={RightDrawerLogo} alt="icon" className="icon-image pointer" />
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          <div
            className={isVendor()?classes.toolbarVendor:classes.toolbar}
            style={
              open
                ? { paddingBottom: "20px" }
                : { display: "flex", justifyContent: "center" }
            }
          >
            {open ? (
           <>
    <img src={isVendor() ? CloseDrawerLogo : AppLogo } alt="Logo" />
    {isVendor() &&
     <>  
   <Divider orientation="vertical" style={{ height: "20px", backgroundColor:"#344054", marginLeft: "10px", marginRight: "10px" }} />
   <img src={user?.vendor_company?.data?.attributes?.vendor_logo.url}  height="35" width="90" alt="Logo" /></>
    }
      </>
   ) : (
  <img src={CloseDrawerLogo} alt="Logo" />
)}

            {!isVendor() && open &&(
              <IconButton onClick={handleDrawerOpen}>
                {!open ? (
                  <></>
                ) : (
                <img src={LeftDoubleLogo}  alt="icon" className="icon-image" />
                )}
              </IconButton>
            )}
          </div>
          <Divider />
          <List className={classes.routes}>
            <ListItem
              button
              className={isVendor()?getClassName("home",true):getClassName("dashboard")} 
              key="Dashboard"
              onClick={isVendor()?()=> history.push("/Home"):()=>history.push("/Dashboard")}
            >
              <ListItemIcon className={open ? "tab-icon-open" : "tab-icon-close"}>
               {isVendor() ? <img src={
                    getClassName("home") !== "non-active"
                      ? HomeActive
                      : Home
                  }
                  alt="icon"
                  className="icon-image"
                />:<img src={
                    getClassName("dashboard") !== "non-active"
                      ? DashboardIconActive
                      : DashboardIcon
                  }
                  alt="icon"
                  className="icon-image"
                />}
               
              </ListItemIcon>
              <ListItemText
                primary={isVendor()?"Home":"Dashboard"}
                className={isVendor()?getClassNameVendor("home") :getClassNameForBack("dashboard")}
              />
            </ListItem>
            {isTeacher() && !isVendor() &&(
              <ListItem
                button
                className={getClassName("classboxes")}
                key="Classboxes"
                onClick={() => history.push("/Classboxes")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("classboxes") !== "non-active" ? LockersIconActive : LockersIcon
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Classboxes"
                  className={getClassNameForBack("classboxes")}
                />
              </ListItem>
            )}
            
            {isVendor() && (
              <ListItem
                button
                className={getClassName("orders",true)}
                key="Orders"
                onClick={() => history.push("/Orders")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("orders") !== "non-active" ? OrderActive : Order
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Orders"
                  className={getClassNameVendor("orders")}
                />
              </ListItem>
            )}

            {!isTeacher() && !isVendor() &&(
              <ListItem
                button
                className={getClassName("requests")}
                key="Requests"
                onClick={() => history.push("/Requests")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("requests") !== "non-active"
                        ? RequestsIconActive
                        : RequestsIcon
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText primary="Requests" className={getClassNameForBack("Requests")} />
                {pendingRequestCount>0?<span className="orange-dot"></span>:null}
              </ListItem>
            )}
            {!isTeacher() && isLead() && (
              <ListItem
                button
                className={getClassName("myschool")}
                key="myschool"
                onClick={() => history.push("/MySchool")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("myschool") !== "non-active"
                        ? activeSchool 
                        : inactiveSchool 
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="My School"
                  className={getClassNameForBack("myschool")}
                />
              </ListItem>
            )}
            {!isTeacher() && !isVendor() &&(
              <ListItem
                button
                className={getClassName("staff")}
                key="Staff"
                onClick={() => history.push("/Staff")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("staff") !== "non-active"
                        ? StaffActive
                        : Staff
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Staff"
                  className={getClassNameForBack("staff")}
                />
              </ListItem>
            )}
            {isTeacher() && !isVendor() &&(
              <ListItem
                button
                className={getClassName("project")}
                key="Projects"
                onClick={() => history.push("/Project")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("project") !== "non-active"
                        ? ClassBoxesIconActive
                        : ClassBoxesIcon
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText primary="Projects" className={getClassNameForBack("project")} />
              </ListItem>
            )}
            {isTeacher() && !isVendor() && (
              <ListItem
                button
                className={getClassName("trainings")}
                key="Traininings"
                onClick={() => history.push("/Trainings")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("trainings") !== "non-active"
                        ? TrainingIconActive
                        : TrainingIcon
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Trainings"
                  className={getClassNameForBack("trainings")}
                />
              </ListItem>
            )}
            {/* {isTeacher() && (
            <ListItem
              button
              className={getClassName("ProjectPlan")}
              key="ProjectPlan"
            >
              <ListItemIcon
                className={open ? "tab-icon-open" : "tab-icon-close"}
              >
                <img
                  src={
                    getClassName("ProjectPlan") !== ""
                      ? ProjectPlanIconActive
                      : ProjectPlanIcon
                  }
                  alt="icon"
                  className="icon-image"
                />
              </ListItemIcon>
              <ListItemText
                primary="Project Plan"
                className={getClassNameForBack("ProjectPlan")}
              />
            </ListItem>
          )} */}
          {
            !isVendor() && (
            <ListItem
              button
              className={getClassName("support")}
              key="Support"
              onClick={() => history.push("/Support")}
            >
              <ListItemIcon className={open ? "tab-icon-open" : "tab-icon-close"}>
                <img
                  src={
                    getClassName("support") !== "non-active"
                      ? SupportIconActive
                      : SupportIcon
                  }
                  alt="icon"
                  className="icon-image"
                />
              </ListItemIcon>
              <ListItemText
                primary="Support"
                className={getClassNameForBack("support")}
              />
            </ListItem>)
}

          {isVendor() && (
              <ListItem
                button
                className={getClassName("products",true)}
                key="Products"
                onClick={() => history.push("/Products")}
              >
                <ListItemIcon
                  className={open ? "tab-icon-open" : "tab-icon-close"}
                >
                  <img
                    src={
                      getClassName("products") !== "non-active" ? VectorActive : Vector
                    }
                    alt="icon"
                    className="icon-image"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Products"
                  className={getClassNameVendor("products")}
                />
              </ListItem>
            )}
          </List>

          <div className="drawer-footer" style={{width:"85%"}}>
            {open && !isVendor() &&(
              <div className={!isTeacher() ? "card" : "card card1"}>
                <div
                  className={!isTeacher() ? "card-body" : "card-body card-body1"}
                >
                  <span className="title">
                    {isTeacher() ? "Your Balance" : `School's Credits`}
                  </span>
                  <div className="desc">
      
                    <span className="credits">{leadOrTeacherCredit}</span>{" "}
                    <span className="credit-title">{getCredits(leadOrTeacherCredit)}</span>
                  </div>
                </div>
                {!isTeacher() && (
                  <Button className="btn" onClick={() => history.push("/AddCredits")}>
                    Add Credits &nbsp;&nbsp;&nbsp;
                    <img src={PlusIcon} alt="add" />
                  </Button>
                )}
              </div>
            )}
            {!open && !isVendor() && (
              <div className="card-close">
                <div className={!isTeacher() ? "desc desc2" : "desc"}>
                  <span className="credits">{getCommaSeperator(leadOrTeacherCredit)}</span>{" "}
                  <span className="credit-title">{getCredits(leadOrTeacherCredit)}</span>
                </div>
                {!isTeacher() && (
                  <div className="desc1" onClick={() => history.push("/AddCredits")}>
                    <img src={PlusIcon} alt="add" />
                  </div>
                )}
              </div>
            )}
           {!isVendor() ?(
             <List>
             {open && (
               <Popover
                 id={id}
                 open={openx}
                 anchorEl={anchorEl}
                 onClose={handleClose}
                 disablePortal={true}
                 anchorOrigin={{
                   vertical: "top",
                   horizontal: "right",
                 }}
                 transformOrigin={{
                   vertical: "top",
                   horizontal: "right",
                 }}
               >
                 <List className="popover-list">
                   {isLead() && (
                     <>
                       {!getLeadMode() && (
                         <>
                           <ListItem
                             onClick={() => {
                               setLoader({ open: true, mode: "teacher" });
                               setTimeout(() => {
                                 changeLeadMode(true);
                                 setLoader({ open: false, mode: "admin" });
                                 history.push("/Dashboard")
                               }, 1500);
                               handleClose();
                             }}
                             className="pointer"
                           >
                             <ListItemText
                               className="pointer"
                               primary={
                                 <span className="list-title">
                                   Teaching Mode
                                 </span>
                               }
                             />
                           </ListItem>
                           <Divider />
                         </>
                       )}
                       {getLeadMode() && (
                         <>
                           <ListItem
                             onClick={() => {
                               setLoader({ open: true, mode: "admin" });
                               setTimeout(() => {
                                 changeLeadMode(false);
                                 setLoader({ open: false, mode: "teacher" });
                                 history.push("/Dashboard")
                               }, 1500);
                               handleClose();
                             }}
                             className="pointer"
                           >
                             <ListItemText
                               className="pointer"
                               primary={
                                 <span className="list-title">Lead mode</span>
                               }
                               secondary={
                                 <span className="list-des">
                                   {user.school.data.attributes.name}
                                 </span>
                               }
                             />
                           </ListItem>
                           <Divider />
                         </>
                       )}
                     </>
                   )}
                   <ListItem
                     className="bg-gray pointer"
                     onClick={() =>{ 
                        handleClose();
                        history.push("/Profile")
                      }}
                   >
                     <ListItemText
                       className="pointer"
                       primary={<span className="list-title">My Profile</span>}
                     />
                     <IconButton aria-label="comments">
                       <img src={open ? UpAero : rightAero} />
                     </IconButton>
                   </ListItem>
                 </List>
                 <Divider />
                 <div className="logout-btn-container">
                   <Button
                     className="btn"
                     variant="contained"
                     onClick={() => {
                       handleLogout();
                       history.push("/Login");
                     }}
                   >
                     Log Out
                   </Button>
                 </div>
               </Popover>
             )}
             <ListItem>
               <ListItemAvatar>
                 <Avatar
                   className="avtar"
                   src={user?.image?.url || ""}
                   onClick={() => history.push("/Profile")}
                 >
                   <span className="avtar-fallback">A</span>
                 </Avatar>
               </ListItemAvatar>
               {open && (
                 <ListItemText
                   primary={
                     <span
                       className="account-title"
                       onClick={() => history.push("/Profile")}
                     >
                       {`${user.first_name + " " + user.last_name}`.length > 12 ? `${user.first_name + " " + user.last_name}`.substring(0, 12) + "..." : user.first_name + " " + user.last_name || ""}
                     </span>
                   }
                   secondary={
                     <span className="role-title">{user.teacher_mode ? getTrimTitle(14,getTeacherName(user.teacher_type)) : getTrimTitle(14,getTeacherName(user.lead_type))}</span>
                   }
                 />
               )}
               <IconButton onClick={handleClick}>
                {openx ? <RightIcon style={{ color: "#007DC6"}} /> : <UpIcon style={{ color: "#007DC6" }}/>}
               </IconButton>
             </ListItem>
           </List>
           ):(
            <div ref={divRef} style={style}>
            <ListItem>
            <ListItemAvatar>
              <Avatar
                className="avtar"
                src={user?.vendor_company?.data?.attributes?.vendor_logo.url|| ""}
                onClick={() => history.push("/CustomisableUserProfiles")}
              >
                <span className="avtar-fallback">A</span>
              </Avatar>
            </ListItemAvatar>
            {open && (
              <ListItemText
                primary={
                  <span
                    className="account-title"
                    onClick={() => history.push("/CustomisableUserProfiles")}
                  >
                      <Tooltip title={user.first_name + " " + user.last_name} arrow>
                <span
                  className="account-title"
                  onClick={() => history.push("/CustomisableUserProfiles")}
                >
                  {`${user.first_name + " " + user.last_name}`.length > 12
                    ? `${user.first_name + " " + user.last_name}`.substring(0, 12) + "..."
                    : user.first_name + " " + user.last_name || ""}
                </span>
              </Tooltip>
                  </span>
                }
               
              />
              
            )}
             <IconButton onClick={handleClickVendor}>
                {openLogOut ?  <ChevronRightIcon fontSize="medium"/> : <ExpandLessIcon/>}
               </IconButton>
          </ListItem>
          {openLogOut && 
            <div className="logout-btn-container"style={{backgroundColor:"white",borderRadius:"10px", borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",borderTopLeftRadius:"0px",borderTopRightRadius:"0px",borderTop: "1px solid #e5e5e5"}}>
            <Button
            className="btn"
              variant="contained"
              onClick={() => {
                handleLogout();
                history.push("/login")
              }}
              style={{
                borderRadius: "10px",
                fontWeight: "bold",
                fontFamily:"Gotham-Medium1, Courier New, Courier, monospace"
              }}
            >
              Log Out
            </Button>
            </div>
            }
          </div>
           )}  
                  
          </div>
        </Drawer>
        <main
          className={showHeader(pathName) ? classes.content1 : classes.content}
          style={{
            height: "fit-content",
            minHeight: "100vh"
          }}
        >
          {children}
        </main>
      </div>
      {loader.open && <SwitchLoader loader={loader} />}
    </>
  );
}

export default withRouter(DrawerComponent);
