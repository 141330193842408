import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { handleTokenError, isUserloggedIn, isVendor } from "../../../components/src/NativeWebRouteWrapper/Utils";
import { ResponseJson } from "./Login.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  errorText: boolean;
  forgotPasswordDialog: boolean;
  openMailDialog: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: any;
  validationApiCallId: any;
  forgotPasswordMailApiCallId: any;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
  

    this.state = {
      errorText: false,
      forgotPasswordDialog: false,
      openMailDialog: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    if (isUserloggedIn() && isVendor()) {
      this.props.navigation.navigate("CustomisableUserProfiles");
    } else if (isUserloggedIn()) {
      this.props.navigation.navigate("Dashboard");
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Web Event Handling
  handleCloseForgetPassword = () => {
    this.setState({ forgotPasswordDialog: !this.state.forgotPasswordDialog });
  };
  handleOpenMail = () => {
    this.setState({ openMailDialog: !this.state.openMailDialog });
  };
  
  handleNavigation = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TeacherSelfOnboarding");
    runEngine.sendMessage(message.id, message);
  }
  handleGoogleLogin = (responseJson?: any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const attrs = {
      unique_auth_id: responseJson.credential,
    };

    const data = {
      type: "social_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.setState({
      loading: true,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  parseJwt = (token: string) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  setUserData = (data: any) => {
    localStorage.setItem("userInfo", data);
  };

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.token
      );
      this.setUserData(this.parseJwt(responseJson.token));
      this.send(msg);
    }
    return true;
  }
  handleLoginSucess(responseJson: ResponseJson) {
    let userData = responseJson?.account?.data;
    this.saveLoggedInUserData({ ...userData?.attributes, id: userData?.id });
    localStorage.setItem("authToken", responseJson.token!);
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        ...responseJson?.account?.data.attributes,
        id: userData?.id,
      })
    );
    if (isVendor()) {
      this.props.navigation.navigate("CustomisableUserProfiles");
    } else if (isUserloggedIn()) {
      this.props.navigation.navigate("Dashboard");
    }
  }

  handleLoginError(responseJson: ResponseJson) {
    if (responseJson?.errors?.[0]?.failed_login) {
      this.setState({
        showSnackbar: false,
        severity: "error",
        message: responseJson.errors[0].failed_login,
        forgotPasswordDialog: false,
      }, this.clearMessage);
    } else {
      this.setState({
        showSnackbar: true,
        severity: "error",
        message:
          responseJson?.errors?.[0]?.failed_login ||
          "Something Went Wrong!",
        forgotPasswordDialog: false,
      });
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson);
      this.setState({ loading: false });
      if (responseJson && responseJson.errors && apiRequestCallId === this.apiEmailLoginCallId) {
       this.handleLoginError(responseJson);
      }
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.apiEmailLoginCallId
      ) {
       this.handleLoginSucess(responseJson);
      }

      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.forgotPasswordMailApiCallId
      ) {
        this.setState({
          showSnackbar: true,
          severity: "success",
          message: "Please check your mail",
          forgotPasswordDialog: false,
          openMailDialog: true,
        });
      }
    }
    // Customizable Area End
  }

  clearMessage = () => {
    setTimeout(() => {
      this.setState({
      message: "",
      })
    }, 2000);
    }

  handleLogin = (values: any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: values.email,
      password: values.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    this.setState({
      loading: true,
    });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  forgotPasswordMail = (values: any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      type: "email_account",
      attributes: { email: values.forgotpasswordEmail },
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordMailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendEmailForForgotPassword
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };
}
