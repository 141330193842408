import React from "react";
import { Grid, Button, Card, LinearProgress } from "@material-ui/core";
import { chatIcon, warningIcon } from "../assets";
import {
  getBtnClass,
  getBtnDisabled,
  getClassboxPartsCheckOutPurchase,
  getCommaSeperator,
  getCommaStringArray,
  getCredits,
  getOrderDetailsWithStatus,
  getSumOfArray,
  getTitleForbtn,
  getUnits,
  getUserDetails,
  handleClassbox,
  oneToMany,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";
import { getCreditHeaderClass, openEmailClient } from "../../../../components/src/utility";

const ClassBoxDetails = ({ state, propsdata, navigation ,handleCheckOut}: any) => {
  const classBoxDetails = propsdata.currentClassboxData;
  let orderDetails = classBoxDetails.attributes.order_details || {
    status: "",
  };
  const userDetail = getUserDetails();

  let orderDetailsWithStatus: boolean = getOrderDetailsWithStatus(orderDetails, state.purchasedClassboxes);
  return (
    <Grid item container sm={5} className="right-container">
      <div className="header">
        <img
          src={classBoxDetails.attributes?.hero_image?.url || ""}
          alt="back-image"
          className="back-image"
        />
        <div
         className={getCreditHeaderClass(userDetail.school, orderDetailsWithStatus)}
        >
          {orderDetailsWithStatus && (
            <div className="warning">
              <img src={warningIcon} alt="icon" />
              &nbsp;&nbsp;
              <span className="title">
                Fix Overdue Checkout to start a new order.
              </span>
            </div>
          )}
          {userDetail.school == null &&
          <div className="unlinkedTeacher">
          <img src={warningIcon} alt="icon" />
          <div className="unlinkedContent">Connect Account To School to Place Order</div>
          </div>
          }
          <div className={userDetail.school == null?"price-container-redborder":"price-container"}>
            <div className="details">
              <span className="txt1">Starting at:</span>
              <span className="txt2">
                {getCommaSeperator(
                  classBoxDetails.attributes.checkout
                    ? classBoxDetails.attributes.checkout_cost
                    : classBoxDetails.attributes.purchase_cost
                )}{" "}
                <span className="txt3">
                  {getCredits(
                    classBoxDetails.attributes.checkout
                      ? classBoxDetails.attributes.checkout_cost
                      : classBoxDetails.attributes.purchase_cost
                  )}
                </span>
              </span>
            </div>
            {<Button
              disabled={
                getBtnDisabled(orderDetailsWithStatus, orderDetails, state.purchasedClassboxes,classBoxDetails)
              }
              className={
                getBtnClass(orderDetailsWithStatus, orderDetails, state.purchasedClassboxes,classBoxDetails)
              }
              id="order-classbox-btn"
              onClick={() => userDetail.school == null ? openEmailClient():handleClassbox(orderDetails, navigation, classBoxDetails, handleCheckOut)}
            >
              {userDetail.school == null? "Request School Connection":getTitleForbtn(orderDetails, state.purchasedClassboxes,classBoxDetails)}
            </Button>
            }

          </div>
        </div>
      </div>
      <div
        className={
          orderDetailsWithStatus
            ? "content padding-top"
            : "content"
        }
      >
        <span className="tags">
          {classBoxDetails.attributes.content_types.join(", ")}
        </span>
        <span className="title1">{classBoxDetails.attributes.title}</span>
        <span className="desc">{classBoxDetails.attributes.description}</span>
        <span className="title title-pad">Classbox Part{oneToMany(getClassboxPartsCheckOutPurchase(classBoxDetails))}</span>
        <div className="list">
          {getClassboxPartsCheckOutPurchase(classBoxDetails).map((item: any) => (
            <div className="item" key={item.id}>
              <span className="head">
                {item.attributes.name} | {item.attributes.supplier?item.attributes.supplier:item.attributes.account.first_name}
              </span>
              <span className="units">
                {item.attributes.quantity} {getUnits(item.attributes.quantity)}
              </span>
            </div>
          ))}
        </div>
        {classBoxDetails.attributes.related_projects.projets
          .length > 0 && <>
            < span className="title">Available Project{oneToMany(classBoxDetails.attributes.related_projects.projets)}</span>
            <span className="time">
              Est. Contact Minutes  :{" "}
              <span className="hours">
                {classBoxDetails.attributes.related_projects.total_hours}
              </span>
            </span>
            <div className="cards">
              {classBoxDetails.attributes.related_projects.projets.map((projectItem: any) => (
                <Card className="card-main" key={JSON.stringify(projectItem)}>
                  <div className="content">
                    <span className="tags">{getCommaStringArray(projectItem.subject_tags)}</span>
                    <span className="title">{projectItem.title}</span>
                  </div>
                  <LinearProgress
                    className="training-progressbar"
                    variant="determinate"
                    value={projectItem.project_status_calculation}
                    style={{
                      overflow: "hidden",
                      position: "absolute",
                      bottom: "0px",
                      width: "100%",
                      height: "5px",
                    }}
                  />
                </Card>
              ))}
            </div>
          </>}
        <span className="title">Professional Development Training{oneToMany(classBoxDetails.attributes.related_trainings)}</span>
        <span className="time">
          Total Training Hours :{" "}
          <span className="hours">
            {getSumOfArray(classBoxDetails.attributes.related_trainings)}
          </span>
        </span>
        <div className="cards">
          {classBoxDetails.attributes.related_trainings.map((item: any) => (
            <Card className="card-main" key={JSON.stringify(item)}>
              <div className="content">
                <span className="tags">{getCommaStringArray(item[1])}</span>
                <span className="title">{item[0]}</span>
              </div>
            </Card>
          ))}
        </div>
        <div className="footer">
          <span className="title">Have a question about this Classbox?</span>
          <Button
            className="btn"
            id="footer-btn"
            onClick={() => navigation.navigate("Support")}
          >
            <img src={chatIcon} alt="icon" /> &nbsp;&nbsp;Contact Instructional
            Coach
          </Button>
        </div>
      </div>
    </Grid >
  );
};

export default ClassBoxDetails;
