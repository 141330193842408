import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialougePopOver from "./dialougePopOver";
import { Divider } from "rsuite";
import { pinBlankIcon, pinWhiteIcon } from "../assets";
import { setStorageData } from "../../../../framework/src/Utilities";
const ProjectComponent = ({
  start,
  item,
  index,
  startStopProject,
  deleteProject,
  handleDialouge,
  state,
  navigation,
}: any) => {
  return (
    <div className="header-card-two">
      <div className="project-topbar">
        <div>
          {!!item.attributes.related_classboxes && (
            <div className="card-main-text-div">
              <img
                src={item.attributes.related_classboxes.emoji_url.url}
                className="classbox-url"
                alt="Icon"
              ></img>
              <span className="card-main-text">
                {" "}
                {item.attributes.related_classboxes.title}
              </span>
            </div>
          )}
        </div>
        <div className="project-topbar-cancle pointer">
          &nbsp;&nbsp;
          <span
            className="starts-stops pointer"
            onClick={() => startStopProject(start, item)}
          >
            <img
              src={start === "Stop teaching" ? pinWhiteIcon : pinBlankIcon}
              alt="icon"
            />
            &nbsp;&nbsp;
            {start}
          </span>
          <Divider vertical />
          <IconButton
            style={{ color: "white" }}
            onClick={handleDialouge}
            id="cancle-btn"
          >
            {/* <IconButton style={{ color: "white" }} onClick={() => deleteProject(item)}> */}

            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div
        id="project-card04"
        style={{ width: "100%", padding: "15px 20px 20px 10px" }}
        className={start === "Stop teaching" ? "pointer" : ""}
        onClick={() =>{
          start === "Stop teaching" &&
          navigation.navigate("ProjectDetails", {
            id: item.attributes.project_id,
            mode: "default",
          })
          setStorageData("mode", "default");
          
        }}
      >
        <div className="project-text-details" style={{ padding: "1%" }}>
          <span className="project-texts">
            {item.attributes.subject_tags.join(", ")}
          </span>
        </div>
        <span className="project-header-text">{item.attributes.title}</span>
      </div>
      <DialougePopOver
        handleDialouge={handleDialouge}
        state={state}
        deleteProject={deleteProject}
        item={item}
      />
      <LinearProgress
        className="progressbar"
        variant="determinate"
        value={item.attributes.project_status_calculation}
        style={{
          overflow: "hidden",
          bottom: "0px",
          width: "100%",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          height: "7px",
        }}
      />
    </div>
  );
};

export default ProjectComponent;
