import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getAuthToken,customSort } from "../../../components/src/NativeWebRouteWrapper/Utils";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type Color = "success" | "info" | "warning" | "error" | undefined;

interface S {
  // Customizable Area Start
  user : any;
  standardsData: any[];
  loading: boolean;
  showSnackbar: boolean;
  severity: Color;
  message: string;
  isLead: boolean;
  page: number;
  pagination: {
    per_page: number;
    current_page: number;
    next_page: null | number;
    prev_page: null | number;
    total_pages: number;
    total_count: number;
  };
  unSelectedId: any;
  leadDetails:{
    first_name:string|null,
    last_name:string|null,
    lead_type:string|null
  };
  user_image:string|null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StandardSelectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStandardsDataApiId: any;
  selectStandardApiCallId: any;
  unSelectStandardApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      user:"",
      standardsData: [],
      showSnackbar: false,
      severity: undefined,
      message: "",
      loading: false,
      isLead: false,
      page: 1,
      pagination: {
        per_page: 5,
        current_page: 1,
        next_page: null,
        prev_page: null,
        total_pages: 0,
        total_count: 0,
      },
      unSelectedId: 0,
      leadDetails:{
        first_name:"",
        last_name:"",
        lead_type:"",
      },
      user_image:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.setState({
      isLead: this.props.navigation.getParam("isLead") === "true",
    });

    this.getStandardsData();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.setState({
        loading: false,
      });
      if (responseJson && !responseJson.errors) {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  handleApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getStandardsDataApiId) {
      this.setState({
        standardsData: [...this.state.standardsData, ...responseJson.data].sort(
          customSort
        ),
        pagination: responseJson.meta.pagination,
        leadDetails:responseJson.meta.recommended_by,
        user_image:responseJson.meta.user_image?.url,
      });
    } else if (apiRequestCallId === this.unSelectStandardApiCallId) {
      this.handleSetFalseStandarsResponse()
    } else if (apiRequestCallId === this.selectStandardApiCallId) {
      this.handleSetTrueStandardResponse(responseJson);
    }
  };

  handleSetTrueStandardResponse = (responseJson: any) => {
    this.setState({
      standardsData: this.state.standardsData
        .map((item: any) => {
          if (item.id == responseJson.data.attributes.standard_set_id) {
            return {
              ...item,
              attributes: {
                ...item.attributes,
                account_added: {
                  added: true,
                  account_standard_id: +responseJson.data.id,
                },
              },
            };
          } else {
            return item;
          }
        })
        .sort(customSort),
    });
  };

  handleSetFalseStandarsResponse = () => {
    this.setState({
      standardsData: this.state.standardsData
        .map((item: any) => {
          if (item.id == this.state.unSelectedId) {
            return {
              ...item,
              attributes: {
                ...item.attributes,
                account_added: null,
              },
            };
          } else {
            return item;
          }
        })
        .sort(customSort),
    });
  }

  // Customizable Area Start
  getStandardsData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStandardsDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStandardsDataApiEndpoint}?per=${6}&page=${
        this.state.page
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  selectStandard = (standardID: string | number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const httpBody = {
      data: {
        attributes: {
          standard_set_id: +standardID,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.selectStandardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setStandardsDataApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  unSetStandard = (standardID: string | number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unSelectStandardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unSetStandardsDataApiEndpoint + standardID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goTODashboard = () => {
    if (!this.canGotoDashboard()) {
      return;
    }
    if (this.state.isLead) {
      localStorage.setItem("isFirstLead", "true");
      localStorage.setItem("showHeader", "true");
      this.props.navigation.navigate("Dashboard", { isFromSignup: true });
    } else {
      localStorage.setItem("isFirstTeacher", "true");
      localStorage.setItem("showHeader", "true");
      this.props.navigation.navigate("Dashboard", { isFromSignup: true });
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbar: false,
      severity: undefined,
      message: "",
    });
    return true;
  };

  updateStateOfStandard = (item: any) => {
    if (item.attributes.account_added) {
      this.setState({
        unSelectedId: item.id,
      });
      this.unSetStandard(item.attributes.account_added.account_standard_id);
    } else {
      this.selectStandard(item.id);
    }
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getStandardsData()
    );
  };

  getStatus = (item: any) => {
    return item.attributes.account_added ? true : false;
  };

  canGotoDashboard = () => {
    this.setState({
      showSnackbar: true,
      message: "Please select at least 1 Standard Set to continue",
      severity: "error",
    });
    return this.state.standardsData.find(
      (item: any) => item.attributes.account_added !== null
    );
  };
  // Customizable Area End
}
