Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";
exports.exampleVideoURL = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
exports.btnExampleTitle = "CLICK ME";
exports.patchAPiMethod = "PATCH";
exports.schoolPatchApi="account_block/schools/";
exports.getApiEndPoint="account_block/accounts/";
exports.getMethod="GET";
exports.getStandardsDataApiEndpoint="bx_block_profile/standard_sets";
exports.setStandardsDataApiEndpoint = "bx_block_profile/account_standards";
exports.unSetStandardsDataApiEndpoint = "bx_block_profile/account_standards/";
exports.deleteMethod = "DELETE";

// Customizable Area End